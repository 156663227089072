.block_newsletter {
  @include search-box;
  font-size: $font-size-sm;
  p {
    padding-top: $small-space;
  }
  form {
    .input-wrapper {
      overflow: hidden;
    }
    input[type=text] {
      padding: 11px;
      &:focus {
        border: 3px $brand-primary solid;
        padding: 8px 8px 9px;
        outline: 0;
      }
      width: 100%;
    }
    input {
      height: 42px;
    }
  }
  margin-bottom: 0.625rem;
}
.block-contact {
  border-left: $gray-light 2px solid;
  padding-left: 3rem;
  color: $gray;
  .block-contact-title{
    color:$gray-darker;
  }
}
.linklist {
  .blockcms-title a {
    color:$gray-darker;
  }
}
.account-list a {
  color: $gray;
  &:hover {
    color: $brand-primary;
  }
}
.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}
.block-social {
  text-align: right;
}
.block-social li {
  height: 2.5rem;
  width: 2.5rem;
  background-color: $gray-light;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0.125rem;
  cursor: pointer;
  &:hover {
    background-color: $brand-primary;
  }
  a {
    display: block;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    &:hover {
      color: transparent;
    }
  }
}
.facebook {
  background-image: url(../img/facebook.svg);
  &::before{
    content:"";
    background-image: url(../img/facebook-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/facebook-gray.svg);
    &:hover {
      background-image: url(../img/facebook-blue.svg);
    }
  }
}
.twitter {
  background-image: url(../img/twitter.svg);
  &::before{
    content:"";
    background-image: url(../img/twitter-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/twitter-gray.svg);
    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }
}
.rss {
  background-image: url(../img/rss.svg);
}
.youtube {
  background-image: url(../img/youtube.svg);
}
.googleplus {
  background-image: url(../img/gplus.svg);
  &::before{
    content:"";
    background-image: url(../img/gplus-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/gplus-gray.svg);
    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }
}
#block_myaccount_infos{
  .myaccount-title a{
    color:$gray-darker;
  }
}
.pinterest {
  background-image: url(../img/pinterest.svg);
  &::before{
    content:"";
    background-image: url(../img/pinterest-blue.svg);
  }
  &.icon-gray {
    background-image: url(../img/pinterest-gray.svg);
    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}
.vimeo {
  background-image: url(../img/vimeo.svg);
}
.instagram {
  background-image: url(../img/instagram.svg);
}
.footer-container {
  margin-top: $medium-space;
  padding-top: $medium-space;
  overflow: hidden;
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.3);
  li {
    margin-bottom: 0.313rem;
  }
  li a {
    color: $gray-dark;
    cursor: pointer;
    font-size: $font-size-sm;
    &:hover {
      color: $brand-primary;
    }
  }
  .links {
    .collapse {
      display: inherit;
    }
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $gray-lighter;
  }
  .footer-container {
    box-shadow: none;
    margin-top: 0;
    .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .links {
      .h3 {
        line-height: 1.5;
        font-size: 1rem;
      }
      .title {
        padding: 0.625rem;
        border-bottom: 1px solid $gray-lighter;
        cursor: pointer;
        .collapse-icons .remove {
          display: none;
        }
      }
      .title[aria-expanded="true"] .collapse-icons {
        .add {
          display: none;
        }
        .remove {
          display: block;
        }
      }
      .navbar-toggler {
        display: inline-block;
        padding: 0;
      }
      .collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      ul {
        background-color: $gray-lighter;
        margin-bottom: 0;
        > li {
          padding: 0.625rem;
          border-bottom: 1px solid white;
          font-weight: bold;
          a {
            color: $gray-darker;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: 1.5rem;
  }
}


/* footer */
#footer{
	padding: 0; margin: 0;
	background: #181818;

    .container{
        max-width: 75%;

        @media screen and (max-width:1000px){
            max-width: 100%;
        }
    }

    .logo_footer {
		position:relative;
		margin-bottom:45px;
		text-align:center;
	}

    .footer-before-top{
        ul{
            display: flex;
            align-items: center;

            @media screen and (max-width:850px){
                flex-direction: column;
            }

            li{
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                color: #808080;

                img{
                    display: block;
                    max-width: 50px; max-height: 50px;
                    margin-bottom: 15px;
                }

                h3, h3 a{
                    font-size: 18px;
                    font-weight: 400;
                    color: #808080;
                    margin: 0;
                }
        
                p, a{
                    font-size: 12px;
                    font-weight: 400;
                    color: #808080;
                }
            }
        }
    }

    .footer-content{
        margin-top: $gap*4;
        color: white;

        @include respond-to(tablette){
            text-align: center;
        }

        .flex{
            .flex-elem{
                margin-right: $gap*3;

                @include respond-to(tablette){
                    margin-right: 0;
                }

                &:last-of-type{ margin-right: 0;}
            }
        }

        h4{
            color: white;
            text-transform: uppercase;
            margin-bottom: $gap;
        }

        p{ color: white; }

        ul{
            li{
                span{
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 50px;
                }

                i{
                    display: inline-block;
                    margin-top: $gap;
                }
            }

            &.contact-infos{
                li{
                    margin-top: $gap;
                }
            }
        }

        a{
            display: inline-block;
            vertical-align: center;
            width: 49%;
            color: white;
            @include trans;

            &:hover{
                color: $main;
                @include trans;
            }
        }

        .img-maps{
            display: block;
            width: 100%; height: auto;
            filter: grayscale(1);
            margin: 0;
        }
    }

    .footer-bottom{
        margin-top: $gap*2;
    }

	
	.links_list {
	
		li {
			display:inline-block;
			a {
				color:#808080;
				font-size:14px;
				font-weight:700;
				text-transform:uppercase;
				padding:0 20px;
				line-height:35px;
				@include trans($duration:300ms);

				&:hover {
					color:$main;
					@include trans($duration:300ms);
				}
				
			}
		}
	}

	.footer_block{
		@media (min-width:544px) and (max-width: 991px) {
			&:nth-child(3) {
				clear:both;
			}
		}
		@media (max-width: 991px) {
			margin: 30px 0 0 0;
		}
		@media (max-width: 767px) {
			margin: 10px 0 0 0;
		}
		h3{
			position: relative;
			display: block;
		    color: #2e2e2e;
			margin: 0 0 35px 0;
			line-height: 30px;
			text-transform: uppercase;
			border: 0;
			font-size: 14px;
			font-weight: 700;
			@media (max-width: 767px) {
				margin: 0 0 15px 0;
				&:after {
					content: '\E313';
					font-family: Material Icons;
					font-size:24px;
					position:absolute;
					right:20px;
					top:3px;
					cursor:pointer;
				}
				&.active:after {
					content: '\E316';
				}
			}
		}
		.footer-about-us {
			.desc_footer {
				margin-bottom:20px;
			}
			.add {
				position:relative;
				overflow:visible;
				padding-left:30px;
				margin-bottom:15px;
			    color: #8d8d8d;
				label {
					color: #8d8d8d;
					text-transform:capitalize;
					margin:0 3px;
					font-weight:normal;
					font-size:12px;
				}
				&:before {
					content:"";
					width:18px;
					height:18px;
					display: inline-block;
					position:absolute;
					left:0;
					top:50%;
					margin-top:-9px;
					color: #8d8d8d;
				}
				&.address:before {
					background: url(../img/icon_address.png) 0 0  no-repeat;
				}
				&.phone:before {
					background: url(../img/icon_mobile.png) 0 0  no-repeat;
				}
				&.email:before {
					background: url(../img/icon_email.png) 0 0  no-repeat;
				}
			}
			
			a {
				color: #8d8d8d;
				display:inline-block;
				&:hover {
					color:$main;
				}
			}
		}
		
		.footer_list  {
			li {
				line-height:30px;
				padding-bottom:5px;
				a {
					font-size:12px;
					color:#707070;
					text-transform:capitalize;
					position:relative;
					display:block;
					padding:0;
					transform: translateX(0);
					@include trans($duration:300ms);

					&:before{
						content: "\f111";
						font-family: $font-icon;
						font-size:6px;
						margin:0 10px 0 0;
						display:inline-block;
						@include trans($duration:300ms);
						vertical-align:1px;
						transform: translateX(0);
					}
					&:hover {
						color:$main;
						@include trans($duration:300ms);
						transform: translateX(5px);

						&:before {
							transform: translateX(5px);
							@include trans($duration:300ms);
						}
					}
				
				}
			}
		}
		
	}	

	.social_follow  {
		h4 {
			color:#9e9e9e;
			font-size:12px;
			font-weight:400;
			line-height:30px;
			margin-bottom:25px;
			text-transform:uppercase;
			display:none;
		}
	
	}

    .flex--footer{
        img{
            filter: grayscale(100%);
        }

        @media screen and (max-width:850px){
            flex-direction: column;
        }
    }

	.footer-top { 
		padding:90px 0;
		text-align:center;

		@media (max-width: 767px) {
			padding:30px 0;
		}
	}
	.footer-bottom { 
	    padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px dashed rgba(255,255,255,0.1);

		address {
			margin: 0;
			color: #676767;
			font-size: 12px;
			line-height: 34px;
			text-align: center;

			a {
				color:$main;

				&:hover {
					text-decoration:underline;
				}
			}
		}

		.payment{
			text-align: right;
			line-height: 34px;

			@media (max-width: 767px) {
				text-align:left;
			}
		}
	}
}
/* end footer */