.top-menu {
  &[data-depth="1"] {
    margin: 0.625rem;
  }
  a:not([data-depth="0"]) {
    display: block;
    padding: 0.625rem;
    color: $gray;
    font-weight: 400;
  }
  a.dropdown-submenu {
    color: $gray-darker;
    text-transform: uppercase;
    font-weight: 600;
  }
  a[data-depth="0"] {
    font-weight: 600;
    padding: 0.188rem $small-space 0.375rem;
    &:hover {
      #header & {
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
  a[data-depth="1"],
  a[data-depth="2"] {
    padding: 0 0.625rem 0.625rem 0;
  }
  .collapse {
    display: inherit;
  }
  .sub-menu {
    &.collapse {
      display: none;
    }
    
    box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
    border: none;
    margin-left: 0.938rem;
    width: calc(100% - 30px);
    min-width: calc(100% - 30px);
    z-index: 18;
    display: none;

    ul[data-depth="1"] > li {
      float: left;
      margin: 0 1.25rem;
    }

    a:hover {
      color: $brand-primary;
    }
  }
  .popover {
    max-width: inherit;
  }
}
.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#mobile_top_menu_wrapper {
  margin-top: 0.625rem;
  padding-bottom: 0.625rem;
  background: white;
  #top-menu {
    margin-bottom: 0.625rem;
  }
  .top-menu {
    color: $gray-darker;
    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: block;
      }
    }
    .collapse-icons .remove {
      display: none;
    }
    .navbar-toggler {
      display: inline-block;
      padding: 0;
    }
    a[data-depth="0"] {
      padding: 0.625rem;
      border-bottom: 1px solid $gray-lighter;
    }
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
    .sub-menu {
      &.collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      box-shadow: none;
      z-index: inherit;
      display: block;
      position: static;
      overflow: hidden;
      margin-left: 0;
      width: 100%;
      min-width: 100%;
      background: $gray-lighter;
      ul[data-depth="0"] > li {
        border-bottom: 1px solid $gray;
      }
      ul[data-depth="1"] {
        margin: 0;
        > li {
          float: none;
          margin: 0;
          a {
            text-transform: none;
          }
        }
      }
      ul {
        padding: 0;
      }
      li > a {
        padding: 0.625rem;
        border-bottom: 1px solid white;
        font-weight: bold;
      }
      ul[data-depth="2"] li a {
        padding-left: 1.25rem;
      }
      ul[data-depth="3"] li a {
        padding-left: 2.5rem;
      }
      ul[data-depth="4"] li a {
        padding-left: 3.75rem;
      }
    }
    .popover {
      border-radius: 0;
    }
  }
  .js-top-menu-bottom {
    a {
      color: $gray;
    }
    .language-selector-wrapper {
      padding: 0.625rem;
      .language-selector {
        display: inline;
      }
    }
    .currency-selector {
      padding: 0.625rem;
    }
    #contact-link {
      padding: 0.625rem;
    }
    .user-info {
      padding: 0 0.625rem;
      a {
        padding: 0.625rem 0;
        display: block;
        width: 100%;
      }
    }
  }
}


// MAIN NAVIGATION (mega menu) 
    .pt_custommenu{
        position: relative;
        text-align: center;

        .pt_menu{
            position: relative;
            display:inline-block;
            margin: 0 $gap;

            &#pt_menu_home{
                display: none;
            }
            
            .parentMenu{
                a{
                    display: block;
                    position: relative;
                    line-height:30px;
                    padding: 20px 0;
                    color:$light;
                    background:transparent;
                    position:relative;
                    font-size: 25px;
                    font-weight: 400;
                    font-family: $typo-title;

                    i{
                        font-size: 14px;
                        display: none;
                        line-height: 27px;
                        float: right;
                        padding: 0 0 0 7px;

                        &:before {
                            content: "\f107";
                            font-family: "FontAwesome";
                        }
                    }

                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        left: 50%; transform: translateX(-50%);
                        bottom: 0;
                        width: 0; height: 4px;
                        border-radius: 1px;
                        background-color: $main;
                        transition: all 75ms ease-in-out;
                    }
                }
            }

            .popup{
                position: absolute;
                bottom: 100% !important; top: auto !important;
                left: 50% !important; transform: translateX(-50%);
                z-index:9;
                box-shadow: 1px 2px 10px 0 rgba(0,0,0,0.1);
                background-color: $black;
                opacity: 0;
                visibility: hidden;
                transition: all 75ms ease-in-out;
                max-width: 300px;

                .cms_mega{
                    a{
                    img { transition: all 1700ms ease; }

                    &:hover{
                        img{ transform: scale(1.07); }
                    }
                    }
                }

                .block1 {
                    overflow: hidden;

                    .column {
                        padding: $gap;
                        text-align:left;

                        .itemMenuName {
                            color:#fff;
                            font-size: 17px;
                            display: block;
                            position: relative;
                            font-weight: 400;
                            font-family: $typo-title;
                            line-height: 37px;

                            &:hover {
                                color:$main;
                            }
                        }

                        .itemSubMenu {
                            padding: 0;
                            margin-top:15px;

                            .itemMenuName {
                                color: #808080;
                                text-transform: capitalize;
                                display: block;
                                font-size: 12px;
                                font-weight: 400;

                                &:before {
                                    content: "\f111";
                                    display: inline-block;
                                    margin-right: 10px;
                                    vertical-align: middle;
                                    color: #c2c2c2;
                                    font-size: 5px;
                                    font-weight: 400;
                                    font-family: "FontAwesome";
                                }
                                &:hover {
                                    color:$main;

                                    &:before {
                                        color:$main;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.act, &.active{
                .parentMenu{
                    a{
                        &:before{
                            width: 100%;
                            transition: all 150ms ease-in-out;
                        }
                    }
                }

                .popup{
                    opacity: 1;
                    visibility: visible;
                    transition: all 75ms ease-in-out;
                }
            }
        }

        .scroll-menu &{
            position: fixed;
            top: 0; left:0; right: 0;
            background: rgba($black, 0.38);
            padding-right: 250px;
            backdrop-filter: blur(9px);
        }

        .scroll-menu--under &{
            .pt_menu{
                .popup{
                    bottom: auto !important;
                    top: 100% !important;
                }
            }
        }
    }

    
// MENU MOBILE
    .pt_custommenu_mobile {
        text-align: left;
        position: relative;
        clear: both;

        .navbar {
            min-height:40px;
            margin: 0;
            border: 0;
            position: relative;
            overflow: hidden;
            background: #333333;
            padding: 0 15px;
            overflow: hidden;
            cursor:pointer;

            .navbar-inner .btn-navbar {
                display: block;
                overflow: hidden;
                margin: 0;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.025em;
                text-transform: uppercase;
                line-height: 40px;

                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 400;
                    font-family: "FontAwesome";
                    cursor:pointer;
                }
            }

            .navbar-inner.navbar-inactive .btn-navbar:after {
                content: "\f03a";
            }

            .navbar-inner.navbar-active .btn-navbar:after {
                content: "\f03a";
            }
        }
    }

    ul#pt_custommenu_itemmobile {
        overflow: hidden;
        margin: 0 0 15px;
        padding: 0;

        li {
            cursor: pointer;
            list-style-type: none;
            margin: 0;
            padding:0 10px;
            display: block;
            position: relative;
            border-bottom:1px solid rgba(255,255,255,0.1);

            &:last-child {
                    border:0;
            }		

            a {
                line-height: 45px;
                color:#fff;
                text-transform: capitalize;
                text-decoration: none;
                font-size: 15px;
                font-weight: 400;

                &:hover {
                    color: $main;
                }
            }

        .grower {
            position: absolute;
            top: 0;
            right: 0;	

            a:after {
                display: block;
                width: 45px;
                height:45px;
                text-align: center;
                color: #fff;
                font-size: 13px;
                font-weight: 400;
                font-family: "FontAwesome";
                    content: "\f055";
            }

            &.active a:after {
                content: "\f056";
            }
        }

        }
    }

    .sub-page .navbar {
        background-color: rgba(46,46,46,0.3);
    }

/* end module megamenu */

// IQIT MEGA MENU
    .iqitmegamenu-wrapper{
        padding-top: 0 !important;        
    }

    #iqitmegamenu-horizontal{
        &.cbp-sticky{
            background-color: rgba($black, 0.7);
            backdrop-filter: blur(9px);

            .container{
                max-width: 100%;
                padding: 0 0 0 $gap;
            }

            .cbp-hrmenu{
                padding-right: 230px;

                @include respond-to(large){
                    padding-right: 148px;
                }
            }
        }
    }
    
    #cbp-hrmenu{
        > ul{
            display: flex;
            justify-content: space-between;

            > li {
                padding: 0 4px;

                > a{
                    display: block;
                    position: relative;
                    line-height: 30px;
                    padding: 20px 0;
                    color: $light;
                    font-family: $typo-title;
                    text-transform: none;
                    font-size: 25px;
                    font-weight: 400;
                    text-align: center;

                    @include respond-to(1550px){
                        font-size: 20px;
                    }

                    @include respond-to(1280px){
                        font-size: 16px;
                    }

                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        left: 50%; transform: translateX(-50%);
                        bottom: 0;
                        width: 0; height: 4px;
                        border-radius: 1px;
                        background-color: $main;
                        transition: all 75ms ease-in-out;
                    }
                }

                .cbp-triangle-container{ display: none; }

                > .cbp-hrsub{
                    margin-left: -6%;
                    margin-right: -6%;
                    width: 112%;
                    border: none;
                    
                    .page-index &{
                        bottom: 100%;
                        margin-bottom: 15px;

                        &.cbp-show{
                            margin-bottom: 0;
                        }
                    }

                    .scroll-menu--under &{
                        margin-bottom: 0;
                        bottom: auto;
                    }

                    .cbp-hrsub-inner{
                        padding: 0;
                        margin: 0 !important;
                        border: none;
                        background-size: 50% auto;
                        background-position: 100% 50%;

                        > .row{
                            margin: 0;
                            align-items: center;

                            .cbp-menu-column{
                                display: flex;
                                justify-content: center;
                                flex: 1;
                                margin: 0;
                                padding: 5vh 0;

                                a{
                                    border-color: transparent;
                                }
                            }

                            .cbp-categories-row{
                                flex-direction: column;

                                .col-xs-12{
                                    text-align: left;

                                    .cbp-category-link-w{
                                        margin: 0; padding: 0;

                                        a{
                                            display: inline-block;
                                            font-family: $typo-title;
                                            color: black;
                                            text-transform: none;
                                            line-height: normal;
                                            padding: 4px 0;
                                            margin: 0;

                                            &:hover{
                                                border-color: $main;
                                            }

                                            @include respond-to(1550px){
                                                font-size: 18px;
                                            }
                        
                                            @include respond-to(11280px){
                                                font-size: 17px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover, &.cbp-hropen{                    
                    > a{
                        &:before{
                            width: 100%;
                            transition: all 150ms ease-in-out;
                        }
                    }
                }
            }
        }

        a.cbp-fade-slide-bottom .cbp-hrsub{
            margin-top: 0;
        }
    }

    #iqitmegamenu-mobile{

        #iqitmegamenu-shower{
            border: none !important;
            padding: 0 !important;

            > .container{
                > span{ display: none; }
            }

            .iqitmegamenu-icon{
                position: relative;
                display: block;
                height: 3px;
                margin: 10px 0;

                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    left:0; top: -10px;
                    width: 100%; height: 3px;
                    background-color: black;
                }

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    left:0; bottom: -10px;
                    width: 100%; height: 3px;
                    background-color: black;
                }
            }
        }
    }

// display_nav
.display_nav{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right:0; top: 0;
    padding: 0 $gap*0.5 !important;
    opacity: 0;
    visibility: hidden;
    background-color: $black;
    transition: all 75ms ease-in-out;
    width: 215px; height: 70px;

    @include respond-to(large){
        width: 135px;
    }

    // contact
    .footer_block{
        display: flex;
        flex: 1;
        width: 40px;
        height: 40px;
        margin-left: 0;
        justify-content: center;

        @include respond-to(large){
            display: none;
        }

        ul{
            li{
                a{
                    display: block;

                    i{
                        display: block;
                        color: white;
                        font-size: 22px;
                        line-height: 41px;
                    }
                }
            }
        }

        &:hover{
            ul{
                li{
                    a{
                        i{ color: $main; }
                    }
                }
            }
        }
    }

    // recherche
    .block-search  {
        position: relative;
        display: flex;
        flex: 1;
        line-height:1;
        justify-content: center;

        .search-icon {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin: 0;
            display:inline-block;

            i{
                display: block;
                color: white;
                font-size: 22px;
                line-height: 41px;
            }

            &:hover{
                i{ color: $main; }
            }

            &:before {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(22,22,27,0.97);
                opacity: 0;
                visibility: hidden;
                transform: scale(0.7);
                transition: transform 0.8s ease,opacity 0.5s ease,visibility 0.5s ease;
            }

            &:after {
                transform: scale(0);
                position: fixed;
                right: 45px;
                top: 47px;
                content: "\f00d";
                font-size: 28px;
                display: inline-block;
                font-family: "FontAwesome";
                font-weight: normal;
                margin: 0 10px;
                vertical-align: -1px;
                color: white;
                z-index: 2;
            }
        }

        &.open {
            .search-icon:before {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
                z-index: 2;
            }
            
            .search-icon:after {
                transform: scale(1);
                transition: all 800ms ease-in-out;
            }
        }
    }

    .scroll-menu &{
        opacity: 1;
        visibility: visible;
        transition: all 75ms ease-in-out;
    }
}

// user
#user_info_top.localiz_block {
    display: flex;
    flex: 1;
    float: none;
    clear: both;
    width: 40px;
    height: 40px;
    justify-content: center;

	.expand-more {
		cursor: pointer;
		font-size:0;
		padding: 0;
		// background: url(../img/icon_account.png) no-repeat -29px 50%;
		font-size: 0;
        height: 100%;

        i{
            display: block;
            position: relative;
            color: white;
            font-size: 21px;
            top: 50%;
            transform: translateY(-50%);
        }
	}

    &:hover{
        .expand-more{
            // background-position: 10px 50%;

            i{color: $main;}
        }
    }
}

/* module cart block panier */
.blockcart {
	position: relative;
    display: flex;
    flex: 1;
	background: transparent;
	height: auto;
	margin: 0;
	line-height:1;
    width: 40px;
    height: 40px;
    justify-content: center;

	@media (max-width: 991px){
		float:none;
		display:inline-block;
	}

	.header {
		position:relative;
		display:inline-block;

		a{
            display: block;
            width: 40px; height: 40px;
			// background: url(../img/icon_cart.png) no-repeat -32px 50%;
			padding: 0;			
			color: #3d3d3d;
			font-size: 12px;
			text-transform: capitalize;
			transition: none;

            i{
                display: block;
                position: relative;
                color: white;
                font-size: 21px;
                top: 50%;
                transform: translateY(-50%);
            }

			.item_total {
				position: absolute;
				left: 30px;
				top: 50%;
				margin-top: -9px;
				font-size: 9px;
				width: 16px;
				height: 18px;
				border-radius: 3px;
				line-height: 18px;
				background: $main;
				color: white;
				text-align: center;

				&:before {
					content: "";
					position: absolute;
					border: 4px solid transparent;
					border-right: 5px solid $main;
					left: -8px;
					top: 6px;
				}
			}
		}

        &:hover a{
			// background-position: 4px 50%;

            i{
                color: $main;
            }
		}
	}

	.body{
		margin:0;
		padding: 0 20px;
		background: $light;
		box-shadow: 0 4px 12px 0 rgba(0,0,0,0.3);
		width:320px;
        left: auto;
		right: 0;
		border:0;

		@media (max-width:479px) {
			width:270px;
		}

		ul li {
			padding: 20px 0;
			border-bottom:$main_border;
			overflow: hidden;

			.img_content {
				float: left;
				margin: 0 20px 0 0;
				position: relative;

				.product-quantity{
					position: absolute;
					top: 5px;
					left: 5px;
					min-width: 25px;
					line-height: 23px;
					border-radius: 100%;
					padding: 2px 0 0;
					text-align: center;
					background: $main;
					color: $light;
					font-size: 12px;
				}
			}

			.right_block {
				overflow: hidden;
				position: relative;
				padding: 0 15px 0 0;

				.product-name {
					display: block;
					overflow: hidden;
					word-wrap: break-word;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #222;
					text-transform: capitalize;
					font-size: 14px;
					line-height: 20px;
				}

				.product-price {
					display: block;
					margin: 10px 0 0;
					color: $main;
				}

				.remove-from-cart {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					display:none;
				}

				.attributes_content {
					display: block;
					font-size: 12px;
					color: #666;
					margin: 5px 0 0;
				}

				strong{
					font-weight:400;
				}
			}
		}

		.price_content {
			overflow: hidden;
			padding: 20px 0;
			border-bottom: $main_border;
			margin-bottom: 20px;

			.price_inline {
				overflow: hidden;
				line-height: 25px;
				.label {
					float: left;
					font-weight: 500;
					color:#3e3d43;
					font-size:14px;
				}
				.value {
					float: right;
					font-weight: 500;
					color:#3e3d43;
					font-size:14px;
				}
			}
		}

		.checkout {
			margin-bottom: 20px;
			a {
				display: block;
				color: $light;
			}
		}
	}

}

@media (min-width: 992px) { 
    .blockcart .body  ul{
        max-height: 285px;
        overflow-y:scroll;
    }
}

.blockcart .body  ul::-webkit-scrollbar-track{
    background-color: #fff;
}

.blockcart .body  ul::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
}

.blockcart .body  ul::-webkit-scrollbar-thumb {
    background-color: rgba(46, 46, 46, 0.85);
    border-radius: 10px;
}

/* style layer cart */
#blockcart-modal .modal-body {
    background:$light;
    padding: 3.125rem 1.875rem;
}
#blockcart-modal .divide-right {
    border-right:$main_border;
    p , strong {
        color:#333333;
        font-size:14px;
        font-weight:600;
    }
}
#blockcart-modal .cart-content { 
    p ,strong{
        color:#333333;
        font-size:14px;
    }
    strong {
        font-weight:600; 
    }
}
#blockcart-modal .product-name {
    font-size: 16px;
    margin-bottom: 20px;
}
/* end style layer cart */
#blockcart-modal button.close {
    color:$light;
    opacity:1;
}	
#blockcart-modal .cart-content .btn {
    margin-top: 20px;
}	
/* end module cart block */