

//systeme de grille FLEX
.flex{
	@include grid;
	&.grille--wrap{flex-wrap:wrap;}

	// @include respond-to(tablette){
	// 	flex-direction: column;
	// }

	&.center-align{
		align-items: center;
	}
}

.flex-elem{
	@include grid-elem;
}

.flex-elem-egalize{
	@include display;
	width:100%;
}