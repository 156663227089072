

@import "animate";

@mixin pos-transition($number) {
	-webkit-transition: all $number ease-in;
	-moz-transition: all $number ease-in;
	-ms-transition: all $number ease-in;
	-o-transition: all $number ease-in;
	transition: all $number ease-in; 
}
@mixin pos-transform($number) {
	-moz-transform: scale3d($number,$number,$number);
	-webkit-transform: scale3d($number,$number,$number);
	-o-transform: scale3d($number,$number,$number);
	-ms-transform: scale3d($number,$number,$number);
	transform: scale3d($number,$number,$number);
}

@function get1024-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (1024 * .01) * 1px; // 1% viewport width
  @return ( calc($target / $vw-context) ) * 1vw;
}

@function get767-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (767 * .01) * 1px; // 1% viewport width
  @return ( calc($target / $vw-context) ) * 1vw;
}
@function get480-vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: (480 * .01) * 1px; // 1% viewport width
  @return ( calc($target / $vw-context) ) * 1vw;
}

.oneline {
	display: block;
	word-wrap: break-word;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@mixin placeholder {
  &::-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
  &:-ms-input-placeholder { @content }
}
 
@mixin placeholder-style {
  color:$light;
}

.fa {
  display: inline-block;
  font-family: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
  > li {
    position: relative;
  }
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: .14285714em;
  text-align: center;
  &.fa-lg {
    left: -1.85714286em;
  }
}

.fa-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa {
  &.fa-pull-left {
    margin-right: .3em;
  }
  &.fa-pull-right {
    margin-left: .3em;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa {
  &.pull-left {
    margin-right: .3em;
  }
  &.pull-right {
    margin-left: .3em;
  }
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}


@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}


.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root {
  .fa-rotate-90, .fa-rotate-180, .fa-rotate-270, .fa-flip-horizontal, .fa-flip-vertical {
    filter: none;
  }
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: $light;
}

.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-gear:before, .fa-cog:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-rotate-right:before, .fa-repeat:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before, .fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before, .fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before, .fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-warning:before, .fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-gears:before, .fa-cogs:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before, .fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before, .fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before, .fa-users:before {
  content: "\f0c0";
}

.fa-chain:before, .fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before, .fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before, .fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-save:before, .fa-floppy-o:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before,  .fa-googleplus:before{
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-unsorted:before, .fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "\f0dd";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before, .fa-undo:before {
  content: "\f0e2";
}

.fa-legal:before, .fa-gavel:before {
  content: "\f0e3";
}

.fa-dashboard:before, .fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-flash:before, .fa-bolt:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-paste:before, .fa-clipboard:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before, .fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before {
  content: "\f111";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-unlink:before, .fa-chain-broken:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
  content: "\f150";
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
  content: "\f151";
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
  content: "\f152";
}

.fa-euro:before, .fa-eur:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before, .fa-usd:before {
  content: "\f155";
}

.fa-rupee:before, .fa-inr:before {
  content: "\f156";
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
  content: "\f157";
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
  content: "\f158";
}

.fa-won:before, .fa-krw:before {
  content: "\f159";
}

.fa-bitcoin:before, .fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before, .fa-gratipay:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-turkish-lira:before, .fa-try:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
  content: "\f19c";
}

.fa-mortar-board:before, .fa-graduation-cap:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before, .fa-car:before {
  content: "\f1b9";
}

.fa-cab:before, .fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
  content: "\f1c5";
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
  content: "\f1c6";
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before, .fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
  content: "\f1d0";
}

.fa-ge:before, .fa-empire:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before, .fa-weixin:before {
  content: "\f1d7";
}

.fa-send:before, .fa-paper-plane:before {
  content: "\f1d8";
}

.fa-send-o:before, .fa-paper-plane-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
  content: "\f1e3";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bell-slash-o:before {
  content: "\f1f7";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-eyedropper:before {
  content: "\f1fb";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-area-chart:before {
  content: "\f1fe";
}

.fa-pie-chart:before {
  content: "\f200";
}

.fa-line-chart:before {
  content: "\f201";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bus:before {
  content: "\f207";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-cc:before {
  content: "\f20a";
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
  content: "\f20b";
}

.fa-meanpath:before {
  content: "\f20c";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-venus:before {
  content: "\f221";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-intersex:before, .fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-facebook-official:before {
  content: "\f230";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-server:before {
  content: "\f233";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-hotel:before, .fa-bed:before {
  content: "\f236";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-train:before {
  content: "\f238";
}

.fa-subway:before {
  content: "\f239";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-yc:before, .fa-y-combinator:before {
  content: "\f23b";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-battery-4:before, .fa-battery:before, .fa-battery-full:before {
  content: "\f240";
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battery-2:before, .fa-battery-half:before {
  content: "\f242";
}

.fa-battery-1:before, .fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "\f244";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-sticky-note-o:before {
  content: "\f24a";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-hourglass-o:before {
  content: "\f250";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "\f251";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
  content: "\f255";
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
  content: "\f256";
}

.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa-hand-spock-o:before {
  content: "\f259";
}

.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-safari:before {
  content: "\f267";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-tv:before, .fa-television:before {
  content: "\f26c";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa-calendar-times-o:before {
  content: "\f273";
}

.fa-calendar-check-o:before {
  content: "\f274";
}

.fa-industry:before {
  content: "\f275";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-map-o:before {
  content: "\f278";
}

.fa-map:before {
  content: "\f279";
}

.fa-commenting:before {
  content: "\f27a";
}

.fa-commenting-o:before {
  content: "\f27b";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-vimeo:before {
  content: "\f27d";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-edge:before {
  content: "\f282";
}

.fa-credit-card-alt:before {
  content: "\f283";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-modx:before {
  content: "\f285";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-usb:before {
  content: "\f287";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-pause-circle-o:before {
  content: "\f28c";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stop-circle-o:before {
  content: "\f28e";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-percent:before {
  content: "\f295";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-envira:before {
  content: "\f299";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-wheelchair-alt:before {
  content: "\f29b";
}

.fa-question-circle-o:before {
  content: "\f29c";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
  content: "\f2a4";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-signing:before, .fa-sign-language:before {
  content: "\f2a7";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
  content: "\f2b3";
}

.fa-fa:before, .fa-font-awesome:before {
  content: "\f2b4";
}

.fa-handshake-o:before {
  content: "\f2b5";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-o:before {
  content: "\f2b7";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-book-o:before {
  content: "\f2ba";
}

.fa-vcard:before, .fa-address-card:before {
  content: "\f2bb";
}

.fa-vcard-o:before, .fa-address-card-o:before {
  content: "\f2bc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-circle-o:before {
  content: "\f2be";
}

.fa-user-o:before {
  content: "\f2c0";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "\f2c2";
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
  content: "\f2c3";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-thermometer-4:before, .fa-thermometer:before, .fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-bathtub:before, .fa-s15:before, .fa-bath:before {
  content: "\f2cd";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-times-rectangle:before, .fa-window-close:before {
  content: "\f2d3";
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
  content: "\f2d4";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-eercast:before {
  content: "\f2da";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-snowflake-o:before {
  content: "\f2dc";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-meetup:before {
  content: "\f2e0";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable {
  &:active, &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.fa, [class^="fa-"] {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

/* addition css */

.oneline {
  display: block;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.animatetab .owl-carousel .owl-item {
	-moz-animation: zoomIn 500ms ease;
	-o-animation: zoomIn 500ms ease;
	-webkit-animation: zoomIn 500ms ease;
	animation: zoomIn 500ms ease;
}


.back-top {
	display: block;
	position: fixed;
	right: 25px;
	bottom: 25px;
	overflow: hidden;
	z-index: 1;

	a {
		background: $dark;
		color: $light;
		width: 40px;
		height: 40px;
		line-height: 40px;
		display: block;
		text-align: center;
		text-decoration: none;
		-webkit-transition: all 500ms ease-in-out;
		-moz-transition: all 500ms ease-in-out;
		-o-transition: all 500ms ease-in-out;
		transition: all 500ms ease-in-out;
		&:after {
			content: "\f102";
			font-family: "FontAwesome";
			font-size: 25px;
		}
		&:hover {
			background: $main;
		}
	}
}


input:focus, button:focus, select:focus, textarea:focus {
  text-decoration: none !important;
  box-shadow: none !important;
  outline:0 !important;
}

a {
  &:hover, &:focus {
    outline: 0 !important;
    text-decoration: none !important;
    box-shadow: none !important;
  }
}
.btn-default, .btn {
  &:hover, &:focus, &:active, &.active {
    outline: 0 !important;
    text-decoration: none !important;
    box-shadow: none !important;
  }
}

a {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

/* edit from here */
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a{
	font-size: 12px;
}
.ui-menu .ui-menu-item a.ui-state-focus, .ui-menu .ui-menu-item a.ui-state-active{
	margin: 0;
	background: none;
	border: 0;
	color: $main;
	cursor: pointer;
}
@media (min-width: 1200px){
	.container {
		max-width: 1170px;
		padding-left:0;
		padding-right:0;
	}
}


button {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-flag{
	margin: 0;
}

/* edit theme */
img {
	max-width:100%;
	margin:auto;
	height:auto;
	
}
ol, ul, dl {
    margin: 0;
	padding:0;
}
i, cite, em, var, address, dfn {
    font-style: normal;
}

p {
    font-size: 12px;
    color: #878787;
    font-weight: 400;
}
body{
	font-family: 'Poppins', sans-serif;
	background: #f2f2f2;
	font-size:13px;
}
@media (min-width:1200px) {
	.container-fluid {
		padding-left:80px;
		padding-right:80px;
	}
}
.unvisible {
    display: none;
}
@media (max-width: 1199px){
	.headerSpace {
		display:none !important;
	}
}
main {
	overflow:hidden;
}

/* item product */
.pos_title {
	position:relative;
	display:block;
	margin-bottom:60px;
	@media(max-width: 767px){
		margin-bottom:30px;
	}
	color:#2e2e2e;
	text-align:center;
	&:before {
		content: url(../img/icon_title.png);
		display: block;
		text-align: center;
	}
	h2 {
		position:relative;
		display:inline-block;
		line-height:30px;
		text-transform:uppercase;
		padding:10px 0 30px 0;

		font{
			font-size:24px;
			font-weight: 700;
		}
		@media(max-width: 991px){
			font-size:20px;
		}
		&:before {
			content:"";
			background: url(../img/line_title.png) no-repeat;
			height:9px;
			width:83px;
			position:absolute;
			bottom:0;
			left:50%;
			-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}
		a {
			color:#2d2d2d;
		}
		
		
	}
}

@-webkit-keyframes fadeInRightProduct {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightProduct {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRightProduct {
  -webkit-animation-name: fadeInRightProduct;
  animation-name: fadeInRightProduct;
}	

.js-product-miniature{
	position:relative;
	display:inline-block;
	width:100%;
	overflow:visible !important;
	margin-bottom:30px;
	&:last-child {
		margin-bottom:0;
	}
	&:hover {
		.img_block {	
		    -webkit-box-shadow: 7px 7px 15px 0 rgba(0,0,0,0.1);
			box-shadow: 7px 7px 15px 0 rgba(0,0,0,0.1);
			@include pos-transition(300ms);
		}
	}
	.img_block {
		position:relative;
		overflow:hidden;	
		-webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
		box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
		@include pos-transition(300ms);
		img {
			width:100%;
			@include pos-transition(300ms);
		}
	}
	.add-to-links  {
		font-size:0;
		position:absolute;
		top:50%;
		right:15px;
		z-index:1;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		@media (max-width:991px){
			right:30px;
		}
		li {
			@media(min-width:1200px){
			opacity:0;
			}
			margin-bottom:4px;
			a.ajax_add_to_cart_button,span.ajax_add_to_cart_button  {
				position:relative;
				line-height:36px;
				height:36px;
				width:36px;
				display:inline-block;
				background-color:$light;
				background: url(../img/button1.png) 0 0 #ebebeb no-repeat;
				font-weight:normal;
				border:0;
				font-size: 0;
				padding: 0;
				border-radius:100%;
				text-align: center;
				
				&:hover {
					background-color:$main;
					background-position: 100% 0;
					@include pos-transition(300ms);				
				}
			}

			a {
				position:relative;
				text-align:center;
				line-height:36px;
				height:36px;
				width:36px;
				border-radius:100%;
				padding:0;
				font-weight:normal;
				font-size:0;
				display:block;
				@include pos-transition(300ms);	
				&.quick-view {
					background: url(../img/button2.png) 0 0 #ebebeb no-repeat;
				}
				&.links-details{
					background: url(../img/button3.png) 0 0 #ebebeb no-repeat;
				}
				&:hover{
					background-color:$main;
					background-position: 100% 0;
					@include pos-transition(300ms);	
				
					
				}
				
			}
			
		}
	}
	@media(min-width:1200px){
		&:hover 	.add-to-links  li {
			top:100%;
			opacity:1;
			&:nth-child(1){
				-webkit-animation: fadeInRightProduct 300ms ease-in-out;
				-moz-animation: fadeInRightProduct 300ms ease-in-out;
				-ms-animation: fadeInRightProduct 300ms ease-in-out;
				animation: fadeInRightProduct 300ms ease-in-out;
				-webkit-transition: opacity 300ms linear;
				-moz-transition: opacity 300ms linear;
				-ms-transition: opacity 300ms linear;
				-o-transition: opacity 300ms linear;
				transition: opacity 300ms linear;	
			}
			&:nth-child(2){
				-webkit-animation: fadeInRightProduct 500ms ease-in-out;
				-moz-animation: fadeInRightProduct 500ms ease-in-out;
				-ms-animation: fadeInRightProduct 500ms ease-in-out;
				animation: fadeInRightProduct 500ms ease-in-out;
				-webkit-transition: opacity 500ms linear;
				-moz-transition: opacity 500ms linear;
				-ms-transition: opacity 500ms linear;
				-o-transition: opacity 500ms linear;
				transition: opacity 500ms linear;
			}
			&:nth-child(3){
				-webkit-animation: fadeInRightProduct 800ms ease-in-out;
				-moz-animation: fadeInRightProduct 800ms ease-in-out;
				-ms-animation: fadeInRightProduct 800ms ease-in-out;
				animation: fadeInRightProduct 800ms ease-in-out;
				-webkit-transition: opacity 800ms linear;
				-moz-transition: opacity 800ms linear;
				-ms-transition: opacity 800ms linear;
				-o-transition: opacity 800ms linear;
				transition: opacity 800ms linear;
			}
		}
	}
	.product_desc {
		padding:20px 0;
		h4 {
			font-weight:normal;
			margin: 0;
			line-height: 1;
		}
		.product_name {
			color:#2e2e2e;
			padding:0;
			margin-bottom:0;
			display: block;
			line-height:20px;
			text-transform:capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font{
				font-size:14px;
				font-weight:500;
			}
			&:hover {
				color:$main;
			}
			
		}
		.product-desc p{
		    font-size: 12px;
			color: #8c8c8c;
			line-height: 25px;
			padding: 15px 0;
			margin:0;
			display:none;
		}
		.hook-reviews {
			margin-top:10px;
			.comments_note {
				direction:ltr !important;
			}
			.star_content {
			    display: inline-block;
				vertical-align: top;
				
			}
			.nb-comments {
				font-size:12px;
			}
		}
		.variant-links {
			margin-top:15px;
			margin-top: 15px;
			background: transparent;
			padding: 0;
			min-height: auto;
			text-align: left;
			display:none;
			a {
				margin:0 5px 0 0;
				margin: 0 5px 0 0;
				border-radius: 100%;
				width: 15px;
				height: 15px;
			}
		}
		
	}
}
.js-product-miniature .product-price-and-shipping {
	background:$light;
	line-height:35px;
	height:35px;
	padding:0 15px;
	display:inline-block;
	position:absolute;
	right:11px;
	bottom:11px;
	font-family: 'Lato', sans-serif;
	-webkit-box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.07);
	box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.07);
	font{
		size:14px;
		
	}
	.price {
		color:#343940;
		font-weight:700;
		font-size:14px;
	}
	.regular-price {
		color: #959595;
		font-size: 14px;
		text-decoration: line-through;
		margin:0 5px;
		font-weight:500;
	}
	.discount-percentage {
		display:none;
	}
}
.new,
.on-sale {
	position: absolute;
    top: 10px !important;
    right: 10px;
	@media (max-width: 1199px) {
		left:10px;
		right:auto;	
	}
    color:$light;
	background:#c4df9a;
	padding:5px 10px;
    line-height: 18px;
	border-radius:4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
	min-width:55px;
	&:after {
	    content: "";
		border: 6px solid transparent;
		border-right: 6px solid #c4df9a;
		position: absolute;
		left: -11px;
		top: 50%;
		margin-top: -6px;
	}
	&:before {
		content: "\f111";
		font-family:$font-icon;
		font-size: 4px;
		margin: 0 8px 0 -8px;
		display: inline-block;
		vertical-align: middle;
	}
	&:hover {
		color:$light;
	}
}
.on-sale {
	z-index:1;
	top:45px !important;
	background:$main;

	&:after {
		border-right: 6px solid $main;
	}
}

.owl-pagination {
	display:inline-block;
	width:100%;
	text-align:center;
	margin-top: 40px;

	@media (max-width: 767px) {
		display:none;
	}

	.owl-page {
		display:inline-block;
		margin:0 6px;

		span{
			display:inline-block;
			width:8px;
			height:8px;
			border-radius:8px;
			background:#d7d7d7;
			@include pos-transition(300ms);

			&:hover {
				background:$main;
				@include pos-transition(300ms);
			}
		}

		&.active {
			span{
				background:$main;
				@include pos-transition(300ms);
			}
		}
	}
}
.owl-buttons {
	> div {
		position:absolute;
		top:50%;
		font-size:0;
		margin-top:-25px;
		cursor:pointer;
		text-align:center;
		width:50px;
		height:50px;
		border-radius:100%;
		line-height:50px;
		color:#2e2e2e;
		background:$light;
		text-align:center;
		cursor:pointer;
		-webkit-box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.07);
		box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.07);
		opacity:0;
		z-index:1;
		@include pos-transition(300ms);

		&:hover {
			color:$main;
			@include pos-transition(300ms);
		}

		&:before {
			font-family: "FontAwesome";
			display: block;
			font-size: 18px;
		}

		&.owl-prev {
			left:0;
			&:before {
			    content: "\f104";
			}
		}

		&.owl-next {
			right:0;
			&:before {
			    content: "\f105";
			}
		}
	}
}
.pos_content {
	position:relative;
	&:hover {
		.owl-buttons {
			> div {
				opacity:1;
				@include pos-transition(300ms);
				&.owl-prev {
					left:-10px;
				}
				&.owl-next {
					right:-10px;
				}
			}
		}
	}
	.js-product-miniature ,.thumb-category,.item_logo,.thumb-container{
		img {
			display:none;
		}
	}
	.owl-item {
		.js-product-miniature,.thumb-category,.item_logo,.thumb-container {
			img {
				display:block;
			}
		}
	}
}
.pos-featured-products,.tab-category-container-slider,.home_blog_post_area,.pos_new_product{
	margin-bottom:80px;
}
.pos_new_product {
	.newSlide  {
		.js-product-miniature {
			margin-bottom:0;
		}
	}
	.leftnewproduct  {
		.js-product-miniature .product_desc .product-desc p {
			display: block;
		}
	}
}
/* end item product */

/* module advertising */
.advertising {
	position: relative;
    margin-bottom: 30px;
    text-align: center;
	overflow:hidden;
	background: $light;
    padding: 15px;
    -webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
	a {
		img {
			width: 100%;
			height:auto;
		}
		&:before {
			background: rgba(255, 255, 255, 0.2);
			bottom: 0;
			content: "";
			left: 50%;
			position: absolute;
			right: 51%;
			top: 0;
			opacity: 1;
			
		
		}
		&:after {
			    background: rgba(255, 255, 255, 0.2);
				bottom: 50%;
				content: "";
				left: 0;
				position: absolute;
				right: 0;
				top: 50%;
				opacity: 1;
			
		}
	}
	&:hover a:before{
			left: 0;
			right: 0;
			opacity: 0;
			@include pos-transition(900ms);
		}
	&:hover  a:after{
		top: 0;
		bottom: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}
}
/* end module advertising */


/* module listcategoris */
.poslistcategories{
	width: 111%;
	margin-left: -5.5%;
	margin-right: -5.5%;

	.owl-pagination {
		position: absolute;
		bottom: 20px;
		text-align: center;
		left: 0;
		right: 0;
	}
	.owl-carousel .owl-wrapper .owl-item {
		padding:0;
	}

	.owl-dots{
		display: none;		
	}

	.list-categories {
		position:relative;
		overflow:hidden;

		&:hover {
			.thumb-category {
				&:before {
					@include pos-transition(400ms);	
					opacity:1;
				}

				img{
					@include pos-transform(1.05);
					@include pos-transition(400ms);	
				}
			}
			.content-listcategoreis{
				@include pos-transition(500ms);	
				opacity:1;
			}
		}
        
		.desc-listcategoreis {
			position:absolute;
			bottom: 40px;
			right:40px; left:40px;
			z-index:1;

			@media (min-width:1920px) {
				right:85px;
				left:85px;
                bottom: 85px;
                padding-top: 85px;
			}
			
			@media screen and (max-width:1000px){
				left: 50px;
			}
		}

		.content-listcategoreis{
			@include pos-transition(500ms);	
			opacity:0;
            padding: 33% 20px 0 20px;
			border-left: 2px solid white;

			@media screen and (max-width:1000px){
				opacity: 1;
			}
			
			a.name_categories{
				position:relative; display: block;
				@include pos-transition(300ms);				
				color: white;
				text-transform:uppercase;
				font-size:40px;
				font-weight:400;
				line-height: normal;
				top: 16px;

				@media screen and (max-width:1000px){
					font-size: 25px;
					top: 10px;
				}
			}
		}

		.thumb-category {
			position:relative;

			&:before {
				content:"";
				position:absolute;
				top:0;
				left:0;
				bottom:0;
				right:0;
				background-color: rgba(0, 0, 0, 0.7);
				pointer-events: none;
				z-index:1;
				opacity:0;
				@include pos-transition(400ms);	

				@media screen and (max-width:1000px){
					opacity: 0.5;
				}
			}

			img {
				@include pos-transition(400ms);	
			}
		}
	}
}
/* end module listcategoris */

/* module featured products */


/* end module featured products */

/* module testimonials */
.testimonials_container{
	 margin-bottom:80px;
	 position:relative;
	 text-align:center;
	 padding: 100px 0;
	 background:#f9f9f9;
	 @media (max-width: 767px) {
		padding:0;
	}		 
	.owl-buttons > div {
		margin:0;
		top:-110px;
		width: 44px;
		border-radius:0;
		box-shadow:none;
		&:before {
			display:none;
		}
		&.owl-prev {
			background: url(../img/links_static.png) 100% 50% no-repeat;
			    -moz-transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			&:hover {
				background-position:0 50%; 
			}
		}
		&.owl-next{
			background: url(../img/links_static.png) 100% 50% no-repeat;
			&:hover {
				background-position:0 50%; 
			}
		}

	}	
 }

.thumb {
	font-size:0;
	margin-bottom:30px;
	li {
		display:inline-block;
		margin:0 15px;
		cursor:pointer;
		border-radius:100%;
		overflow:hidden;
		width:100px;
		height:100px;
		@include pos-transform(0.8);
		@include pos-transition(300ms);	
		img {
			@include pos-transition(300ms);
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		&.active {
			@include pos-transform(1);
			@include pos-transition(300ms);
			img {
				@include pos-transition(300ms);
				-webkit-filter: grayscale(0);
				filter: grayscale(0);
			}
		}
	}
}

 .item-testimonials {
	 position:relative;
	text-align:center;
	overflow:hidden;
	.item {
		display:inline-block;
		width:100%;
		.img {
			width:70px;
			
			margin-bottom:10px;
			display:inline-block;
			img {
					width:100%;
				}
			 
		}
		.content_test {
			p {
				margin:0;
			}
			.des_namepost {
				position:relative;
				font-size: 14px;
				font-weight:600;
				line-height:1;
				color:#606060;
				display: block;
				text-transform:uppercase;
				letter-spacing:0.025em;
				display:inline-block;
				&:hover {
					color:$main;
				}
				&:before {
					content:"";
					position:absolute;
					top:50%;
					bottom:0;
					left:-25px;
					right:-25px;
					height:1px;
					background:#d6d7d9;
					z-index:-1;
				}
				span {
					background:#f9f9f9;
					display:inline-block;
					padding:0 10px;
				}
			
			}
			.des_email {
				font-weight: 300;
				font-size: 12px;
				line-height: 1;
				text-transform: capitalize;
				color: #606060;
				font-style:italic;
				letter-spacing: 0.2em;
			}
			.des_testimonial {
				font-weight: 300;
				font-size: 12px;
				line-height: 25px;
				text-transform: capitalize;
				color: #606060;
				padding: 35px 150px;
				@media (max-width: 767px) {
					padding: 35px ;
				}
				letter-spacing: 0.2em;
			}
			
		}
		
	
	}
}
/* end module testimonials */
/* module featured */

.pos-featured-products {
	.banner-box img {
		width:auto;
		@media (max-width: 991px) {
			margin-bottom:30px;
		}
	}
	.js-product-miniature {
		margin:0;
	}
}
/* end module featured */
/* module bestseller */
.pos-bestsellers-product {
    background: white;
    padding: 15px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    -webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
	.pos-title-bestseller{
		position: relative;
		display: block;
		border-bottom: 0;
		margin: 0;
		text-transform: uppercase;
		margin-bottom: 20px;
		border-bottom: 2px solid #363636;
		h2 {
			display: inline-block;
			position: relative;
			padding-bottom: 10px;
			line-height: 1;
			color: #363636;
			font-size: 16px;
			margin:0;
			font-weight: 700;
		}
	}
	.js-product-miniature {
		display:inline-block;
		width:100%;
		&:last-child {
			margin:0;
			border:0;
		}
		.img_block {
			float:left;
			width:90px;
			padding:0;
			margin-right:20px;
			@media (min-width: 768px) and (max-width: 991px) {
				width:auto;
				margin:0;
				margin-bottom:20px;
	
			}
			&:before {
				display:none;
			}
			
		}
		.product_desc {
			background:transparent;
			overflow:hidden;
			display:block;
			padding:0;
			width:auto;
			@media (min-width: 768px) and (max-width: 991px) {
				display:inline-block;
				width:100%;
				overflow:visible;
			}
			.product-name a {
				margin-bottom:20px;
			}
			.product-price-and-shipping {
				position:static;
				box-shadow:none;
				padding:0;
				height:auto;
				margin-top:5px;
				@media (min-width: 992px) and (max-width: 1199px) {				
					.regular-price {
						display: block;
						margin: 0;
					}
				}	
			}
		}
	}
	.owl-carousel .owl-wrapper .owl-item {
		padding: 0;
	}
	.owl-buttons  > div {
		top:0;
		margin-top:-55px;
		width:25px;
		height:25px;
		line-height:25px;
		opacity:1;
		&:before {
			font-size:14px;
		}
		&.owl-prev {
			left:auto;
			right:30px;		
		}
		&.owl-next{
			right:0;
		}
		
	} 
}
/* end module bestseller */
/* module contact */

.contact-link {
	float: right;
	clear: both;
	z-index: 10;
	.info_box {
		position: relative;
		line-height: 30px;
		text-shadow: none;
		font-size: 12px;
		display: block;
		font-weight: 600;
		width: 44px;
		height: 50px;
		cursor: pointer;
		background: white;
		padding: 0 7px;
		&:last-child {
			.current {
				border:0;
			}
		}
		.icon-phone {
			float: right;
			background: url(../img/icon_phone.png) no-repeat  center left;
			width: 30px;
			height: 50px;
			padding: 10px 0;
			background-color:$light;
			border-bottom:$main_border;
		}
		.icon-fax {
			float: right;
			background: url(../img/icon_fax.png) no-repeat  center left;
			width: 30px;
			height: 50px;
			padding: 10px 0;
			background-color:$light;
			border-bottom:$main_border;
		}
		.icon-email {
			float: right;
			background: url(../img/icon_mail.png) no-repeat  center left;
			width: 30px;
			height: 50px;
			padding: 10px 0;
			background-color:$light;
			border-bottom:$main_border;
		}
		&:hover {
			.icon-phone {
				background-position: center right;
			}
			.icon-fax {
			    background-position: center right;
			}
			.icon-email {
			    background-position: center right;
			}
		}
		a {
			color: #363636;
			&:hover {
				color:$main;
			}
		}
		.dropdown-menu {
			display: none;
			background: white;
			position: relative;
			padding: 0 15px;
			line-height: 40px;
			border-radius: 4px;
			position: absolute;
			top: 50%;
			cursor:none;
			margin-top: -20px;
			margin-right: 15px;
			right: 100%;
			left:auto;
			color: #363636;
			font-size:12px;
			font-weight:600;
			white-space: nowrap;
			-webkit-box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
			box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
			&:before,&:after {
				content: "";
				position: absolute;
				border: 7px solid transparent;
				border-left: 7px solid white;
				right: -13px;
				top: 50%;
				margin-top: -7px;
			}
			&:after  {
			    border-left: 7px solid rgba(0,0,0,0.1);
				right: -14px;
				z-index:-1;
			}
		}
	}
}
	
/* end module contact */

/* module social */
#header {
	.social_follow{
		float:right;
		width:auto;
		padding:0;
		li {
			display:inline-block;
			background-image:none !important;
			padding:0 10px;
			margin:0;
			&:last-child {
				padding-right:0;
			}
			a {
				position:relative;
				display: inline-block;
				vertical-align:middle;
				color:$light;
				background:transparent;
				font-size: 0;
				line-height:13px; 
				width: auto;
				height: auto;
				padding:0;
				z-index:1;
				border:0;
				@include pos-transition(300ms);

				&:before {
					padding:0 !important;
					font-size:12px;
					display: inline-block;
					font-family: $font-icon;
					font-weight: normal;
					vertical-align:middle;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
				&:hover {
					color:$main;
					@include pos-transition(300ms);
				}
				
			}
			&.facebook {
				a {
					
					&:before {
						content: "\f09a";
					}
				}
			}
			&.twitter {
				a {
					&:before {
						content: "\f099";
					}
				}
			}
			&.rss {
				a {
					&:before {
						content: "\f09e";
					}
				}
			}
			&.youtube {
				a {
					&:before {
						content: "\f167";
					}
				}
			}
			&.googleplus{
				a {
					&:before {
						content: "\f0d5";
					}
				}
			}
			&.pinterest {
				a {
					&:before {
						content: "\f0d2";
					}
				}
			}
			&.vimeo {
				a {
					&:before {
						content: "\f194";
					}
				}
			}
			&.instagram {
				a {
					&:before {
						content: "\f16d";
					}
				}
			}
		}
	}
	h4 ,.payment{
		display:none;
	}
}

.social_follow {
	display:inline-block;
	width:100%;
	margin-top:45px;
	li {
		display:inline-block;
		margin: 0 10px;
		background-image:none !important;
		padding:0;
		a {
			position:relative;
			display: inline-block;
			vertical-align:middle;
			color:#a1a1a1;
			background:transparent;
			font-size: 0;
			line-height:32px;
		    border: 1px solid #e8e8e8;
			border-radius:100%;
			padding:0;
			width:34px;
			height:34px;
			text-align:center;
			z-index:1;
			@include pos-transition(300ms);

			&:before {
				padding:0 !important;
				font-size:16px;
				display: inline-block;
				font-family: $font-icon;
				font-weight: normal;
				vertical-align:middle;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
			&:hover {
				color:$main;
				border-color:$main;
				@include pos-transition(300ms);
			}
			
		}
		&.facebook {
			a {
				
				&:before {
					content: "\f09a";
				}
			}
		}
		&.twitter {
			a {
				&:before {
					content: "\f099";
				}
			}
		}
		&.rss {
			a {
				&:before {
					content: "\f09e";
				}
			}
		}
		&.youtube {
			a {
				&:before {
					content: "\f167";
				}
			}
		}
		&.googleplus {
			a {
				&:before {
					content: "\f0d5";
				}
			}
		}
		&.pinterest {
			a {
				&:before {
					content: "\f0d2";
				}
			}
		}
		&.vimeo {
			a {
				&:before {
					content: "\f194";
				}
			}
		}
		&.instagram {
			a {
				&:before {
					content: "\f16d";
				}
			}
		}
	}
}
/* end module social */

/* module search block */
@media (min-width:768px) and (max-width:1199px) {
	.fixed_search {
		position:fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;	
	}
}

.search_top {
	display: none;
    position: fixed;
    right: 0;
    top: 50%;
    left: 0;
    margin-top: -30px;
    padding: 0;
	background: transparent;
    z-index: 3;
	@media (min-width:768px) and (max-width:1199px) {
		z-index: 999;
	}
	border:0;
	form {
		position: relative;
		max-width: 70%;
		margin: 0 auto;
		.text_input {
			padding: 10px 70px 10px 10px;
			height: 60px;
			width: 100%;
			z-index: 1;
			color: white;
			background: transparent;
			border: 0;
			border-bottom: 1px solid rgba(255,255,255,0.5);
			font-size:14px;
			&::placeholder {
				color: $light;
			}
		}
		button {
			background: transparent;
			display: block;
			position: relative;
			border: none;
			color: white;
			width: 60px;
			text-align: center;
			line-height: 60px;
			height: 60px;
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			&:hover {
				color: $main;
			}
		}
	}
}

@media (max-width: 767px) {
	.block-search  {
		float:none;
		display:inline-block;
		width:100%;
		margin-bottom:5px;
		.search-icon {
			display:none;
		}
		.search_top  {
			position:static;
			margin:0;
			display:inline-block;
			width:100%;
			form {
				position: relative;
				max-width: 100%;
				margin: 0 auto;
				.text_input {
					border-bottom: 1px solid rgba(255,255,255,0.1);
					border-top: 1px solid rgba(255,255,255,0.1);
					color:$light;
					&::placeholder {
						color:$light;
					}
				}
				button {
					color:$light;
					&:hover {
						color:$main;
					}
				}
			}	
		}
		
	}
	.sub-page {
		.block-search {
			.search_top   {
				.text_input {
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					border-top: 1px solid rgba(255, 255, 255, 0.1);
					color: $light;
					&::placeholder {
						color:$light;
					}
				}
				button {
					color: $light;
					&:hover {
						color:$main;
					}
				}
			}
			
		}
	}
}
.ui-corner-all {
	overflow:hidden;
	max-height: 300px;
	overflow-y:scroll;
	li a {
		display:inline-block !important;
		width:100%;
		overflow: visible;
		max-height: none;
		overflow-y: auto;
		img {
			float:left;
			margin-right:30px;
		}
		span {
			margin:20px 0;
			display:inline-block;
			font-size:12px;
			&.separator {
				margin-left:5px;
				margin-right:5px;
			}
			&.product {
				font-size:13px;
				font-style:italic;
			}
		}
		.price {
			font-weight:600;
		}
		
	}
}
/* end module search block */

/* setting */
.setting_top  {
    float: right;
    position: relative;
    width: 44px;
    height: 50px;
    cursor: pointer;
    background: white;
    padding: 0 7px;
	.icon-setting {
		display:inline-block;
		background: url(../img/icon_setting.png) no-repeat  center left;
		width: 30px;
		height: 50px;
		padding: 10px 0;
		background-color:$light;
		border-bottom: $main_border;
		cursor:pointer;
		
	}
	&:hover 	.icon-setting, &.open  .icon-setting{
		background-position: center right;
	}
	.content-setting {
		background:$light;
		padding: 20px 25px;
		position: absolute;
		right: 100%;
		left:auto;
		top: 50%;
		cursor:none;
		margin-top: -25px;
		margin-right: 15px;
		-webkit-box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
		box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.2);
		min-width: 250px;
		border-radius: 4px;
		&:before,&:after {
			content: "";
			position: absolute;
			border: 7px solid transparent;
			border-left: 7px solid white;
			right: -13px;
			top: 0;
			margin-top: 15px;
		}
		&:after  {
			border-left: 7px solid rgba(0,0,0,0.1);
			right: -14px;
			z-index:-1;
		}
	}
	
}
.localiz_block {
    float: left;
    position: relative;
	@media (max-width: 991px) {
		display:inline-block;
		float:none;
	}
	&:hover,&.open {
		.expand-more {
			color:$main;
		}
	}
	.expand-more {
		text-shadow: none;
		display:block;
		cursor: pointer;
		text-transform: uppercase;
		position: relative;
		overflow: hidden;
		font-weight: 600;
		padding: 0 15px;
		line-height: 25px;
		color: $light;
		font-size: 12px;
		text-transform: uppercase;
		
	}
	.dropdown-menu {
		background: white;
		-webkit-box-shadow: 0 1px 7px -2px rgba(0,0,0,0.3);
		-moz-box-shadow: 0 1px 7px -2px rgba(0,0,0,0.3);
		box-shadow: 0 1px 7px -2px rgba(0,0,0,0.3);
		position: absolute;
		border:0;
		padding: 10px 15px;
		text-transform: capitalize;
		top: 100%;
		left: 0;
		min-width: 150px;
		z-index: 2;
	}
	.dropdown-item {
		line-height: 30px;
		font-size: 12px;
		padding: 0 5px;
		color: #3c3c3c;
		&:hover {
			color:$main
		}
	}
	.current a {
		color:$main;
	}
}



/* end setting */
/* welcome */
.poswelcome {
	position:absolute; 
	top:50%;
	left:0;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform:  translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	@media (max-width: 991px) {
		position:static;
		margin:20px 0;
		-moz-transform: none;
		-webkit-transform:none;
		-o-transform:  none;
		-ms-transform: none;
		transform: none;
	}
	p {
		line-height:25px;
		margin:0;
		color:#3e3e3e;
		font-size:12px;
		letter-spacing:0.025em;
	}
}
/* end welcome */
/* module productcomments */
//addons comments products
form#sendComment fieldset {
	padding: 10px;
	border: 1px solid #cccccc;
	background: #eeeeee;
}

form#sendComment h3 {
	font-size: 14px;
}

#new_comment_form p.text {
	margin-bottom: 0;
	padding-bottom: 0;
}

#sendComment p.text label,
#sendComment p.textarea label {
	display: block;
	margin: 12px 0 4px;
	font-weight: bold;
	font-size: 12px;
}

#sendComment p.text input {
	padding: 0 5px;
	height: 28px;
	width: 498px;
	border: 1px solid #cccccc;
	//background: url(../img/bg_input.png) repeat-x 0 0 $light;
}

#sendComment p.textarea textarea {
	height: 80px;
	width: 508px;
	border: 1px solid #cccccc;
}

#sendComment p.submit {
	padding: 0;
	text-align: right;
}

#sendComment p.closeform {
	float: right;
	padding: 0;
	height: 12px;
	width: 12px;
	text-indent: -5000px;
	//background: url(../img/icon/delete.gif) no-repeat 0 0;
}

.star {
	position: relative;
	top: -1px;
	float: left;
	width: 14px;
	overflow: hidden;
	font-size: 12px;
	font-weight: normal;
}

.star {
	display: block;
	a {
		display: block;
		position: absolute;
		text-indent: -5000px;
	}
}

div.star {
	&:after {
		content: "\f006";
		font-family: "FontAwesome";
		display: inline-block;
		color: #b6b6b6;
	}
}

div.star.star_on {
	display: block;
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: $main;
	}
}

div.star.star_hover {
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: $main;
	}
}
// module productcomments
.rating {
	clear: both;
	display: block;
	margin: 2em;
	cursor: pointer;
}

.rating:after {
	content: ".";
	display: block;
	height: 0;
	width: 0;
	clear: both;
	visibility: hidden;
}

.cancel, .star {
	overflow: hidden;
	float: left;
	margin: 0 1px 0 0;
	width: 14px;
	height: 16px;
}

.cancel a, .star a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0;
}

div.star_on a {
	background-position: 0 -16px;
}

div.star_hover a,
div.star a:hover {
	background-position: 0 -32px;
}

.pack_content {
	margin: 10px 0 10px 0;
}

.confirmation {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #e6db55;
	font-size: 13px;
	background: none repeat scroll 0 0 lightyellow;
}

#page .rte {
	background: transparent none repeat scroll 0 0;

	em {
		font-style: italic !important;
	}
}

.listcomment {
	list-style-type: none;
	margin: 0 0 20px 0 !important;
}

.listcomment li {
	padding: 10px 0;
	border-bottom: 1px dotted #ebebeb;
	color: #666666;
}

.listcomment .titlecomment {
	display: block;
	font-weight: bold;
	font-size: 12px;
	color: #ebebeb;
}

.listcomment .txtcomment {
	display: block;
	padding: 5px 0;
	color: #ebebeb;
}



#product_comments_block_extra {
	padding:0;
	font-weight: normal;
	line-height: 18px;
	margin: 10px 0 0 0;
	.comments_note {
		margin-bottom: 5px;
		display: inline-block;
	}
	.comments_note span {
		display:none;
	}
	.comments_note span,
	.star_content {
		float: left;
		color: #333333;
	}
	.star_content {
		margin-top: 1px;
	}
	div.star {
		&:after {
			content: "\f006";
			font-family: $font-icon;
			display: inline-block;
			color: #b6b6b6;
		}
	}
	div.star_on {
		&:after {
			content:"\f006";
			font-family: $font-icon;
			display: inline-block;
			color:$main;
		}
	}
	.comments_advices {
		display:inline-block;
		vertical-align: top;
		margin: 0;
	}
}

.comments_advices {
	li{
		display:inline-block;
		line-height: 21px;
		@media (min-width: 768px) and (max-width: 991px) {	//min 768px max 991px
			display: block;
			line-height: 20px;
		}
	}
	a {
		text-decoration: none;
		color:#919191 !important;
		position:relative;
		margin-right: 8px;
		line-height: 21px;
		padding: 0;
		font-size:12px;
		margin-bottom:10px;
		cursor:pointer;
		display:inline-block;
		@media (min-width: 768px) and (max-width: 991px) { //min 768px max 991px
			float: none;
			line-height: 20px;
		}
		&:before{
			display: inline-block;
			font-family: $font-icon;
			font-size: 15px;
			font-weight: normal;
			line-height: 1;
			margin-right: 6px;
		}
		&.reviews:before{
			content: "\f0e5";
		}
		&.open-comment-form:before{
			content: "\f040";
		}
		&:hover {
			color:$main !important;
		}
		&.reviews{
			margin-right: 9px;
			@media (min-width: 768px) and (max-width: 991px) { // min 768px max 991px
				margin-right: 0;
				padding: 0;
				border: none;
			}
		}

	}
}


/* pop-in add grade/advice ********************************************************************* */
#fancybox-wrap {
	width: 585px;
}
#fancybox-content {
	width: 585px;
	border-width: 0;
}
#new_comment_form {
	overflow: hidden;
	color: #333333;
	text-align: left;
	h2{
		margin-bottom: 10px;
		border: 0;
		text-transform: capitalize;
	}
	.title {
		padding: 10px;
		font-size: 13px;
		color: $light;
		text-transform: uppercase;
		background: $dark;
	}
	ul.grade_content {
		list-style-type: none;
		margin: 0 0 20px 0;
		li{
			width: 50%;
		}
		span{
			display:inline-block;
			padding: 0 10px;
			width: 150px;
			font-weight: bold;
		}
		.cancel {
			margin-right: 5px;
		}
	}
	.product {
		padding: 15px;
		img{
			border: $main_border;
		}
		.product_desc {
			line-height: 18px;
			color: #6f6f6f;
			.product_name {
				padding: 10px 0 5px;
				font-size: 13px;
				color: #000;
			}
		}
	}
	.new_comment_form_content {
		padding: 15px;
		.intro_form {
			padding-bottom: 10px;
			font-weight: bold;
			font-size: 12px;
		}
	}
	label {
		display: block;
		margin: 12px 0 4px 0;
		font-weight: bold;
		font-size: 12px;
	}
	input,
	textarea  {
		padding: 0 5px;
		height: 28px;
		width: 100%;
		border: 1px solid #ebebeb;
		background: $light;
	}
	textarea {
		height: 80px;
	}
	.submit {
		margin-top: 20px;
		padding: 0;
		font-size: 13px;
		text-align: right;
	}

	#criterions_list {
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 15px;
		list-style-type: none;
		li{
			margin-bottom: 10px;
		}
		label {
			display: inline;
			float: left;
			margin: 0 10px 0 0;
		}
		.star_content {
			float: left;
		}
	}
	#new_comment_form_footer {
		margin-top: 20px;
		font-size: 12px;
	}
}
/* TAB COMMENTS ******************************************************************************** */
#product_comments_block_tab {
	margin: 0 0 20px 0;
	div.comment {
		margin: 0 0 14px;
		padding-top: 14px;
		border-top: $main_border;
		&:first-child{
			padding: 0;
			border: none;
		}
		.comment_author {
			line-height: 18px;
			span{
				font-weight: 600;
				text-transform: capitalize;
				font-size: 14px;
				color: #6c6c6c;
			}
			.star_content {
				display: inline-block;
				vertical-align: middle;
				margin: 0 0 0 5px;
			}
		}
		.comment_details {
			overflow: hidden;
			margin-top: 20px;
			h4 {
			    font-weight: 600;
				text-transform: capitalize;
				font-size: 14px;
				color: #6c6c6c;
			}
			p {
				color: #adadad;
				font-size:12px;
			}
			@media (max-width: 767px) { //max 767px
				ul {
					list-style-type: none;
					margin: 0;
					li {
						padding: 2px 0 2px;
						&:before{
							font-family: $font-icon;
							content: "\f0da";
							color: #333333;
							margin-right: 8px;
						}
					}
				}
			}
		}
	}
	div.star {
		&:after {
			content: "\f006";
			font-family: $font-icon;
			display: inline-block;
			color: #b6b6b6;
		}
	}
	div.star_on {
		&:after {
			content: "\f006";
			font-family: $font-icon;
			display: inline-block;
			color: $main;
		}
	}
	.comment_author_infos {
		clear: both;
		padding-top: 9px;
		strong{
			display: block;
			color: #333333;
			margin-bottom:10px;
		}
		em {
			color: #adadad;
		}
	}
	a {
		text-decoration: none;
		font-weight: 400;
		color:$light;
	}

	span.report_btn {
		cursor: pointer;
	}
	span.report_btn:hover {
		text-decoration: underline;
	}
}
.fl {
	float: left;
}
.fr {
	float: right;
}
.tabs .tab-pane {
	padding:0;
}
#products .comments_note, .featured-products .comments_note, .product-accessories .comments_note {
    text-align: left;
    color: #acaaa6;
    font-size: 12px;
}
/* end module productcomments */


/* module slideshow */
.nivo-html-caption {
    display: none;
}
@-webkit-keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	@keyframes myfirst {
		from {width: 0;}
		to {width: 100%;}
	}
	.timethai.pos_hover{
		-webkit-animation-play-state: paused;
		animation-play-state: paused;
		
}
.slideshow_container .pos-slideshow{
	&:hover .nivo-directionNav a {
		opacity:1;
		@include pos-transition(300ms);
	}
}
.slideshow_container .pos-slideshow .nivo-directionNav a {
	display: block;
	width: 45px;
	height: 45px;
	line-height:45px;
	border-radius:0;
	color:#2e2e2e;
	background:transparent;
	text-align: center;
	font-size: 0;
	position: absolute;    
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 8;
	cursor: pointer;
	top:50% ;
	opacity:0;
	@include pos-transition(300ms);	
	@media (max-width: 767px) {
		display: none;
	}
	&.nivo-prevNav{
		background: url(../img/prev_slider.png) 0 center;
		left:50px;
		@media (max-width:1199px) {
			left:get1024-vw(50px);
		}
		&:hover{
			background-position:100% center;
			@include pos-transition(300ms);	
	
		}
		
	}
	&.nivo-nextNav{
		background: url(../img/next_slider.png) 100% center;
		right:50px;
		@media (max-width:1199px) {
			right:get1024-vw(50px);
		}
		&:hover{
			background-position:0 center;
			@include pos-transition(300ms);	
	
		}
	}
	
}
.slideshow_container .pos-slideshow .nivo-controlNav {
		position: absolute;
		bottom:100px;
		@media (max-width:1199px) {
			bottom:get1024-vw(30px);
		}
		left:0;
		right:0;
		text-align:center;
		padding:0 30px;
		z-index: 8;
		@media (max-width: 767px) {
			display: none;
		}
		a{
		
			display:inline-block;
			height: 10px;
			border-radius:100%;
			font-size: 0;
			width: 10px;
			margin:0 4px;
			text-align:center;
			cursor: pointer;
			position: relative;
			z-index: 8;
			background:#dcdcdc;
			@include pos-transition(300ms);	
			&.active,&:hover{
				background:#7d7d7d;
			}
		}
}

.banner7-des {
	position:absolute;
	left:70px;
	top:50%;
	right:0;
	z-index:8;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height:1;
	text-align:left;
	direction:ltr !important;
	p {
		margin:0;
	}
}
.desc1  {
	padding-left:100px;
	.title1 {
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light; 
		font-weight:600;
		text-transform:capitalize;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
	}
	.title2{
		position:relative;
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light; 
		line-height:1;
		font-weight: 600;
		-webkit-animation-delay: 0.5s;
		animation-delay: 0.5s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
		span {
			color:$main;
		}
		
	}
	.title3{
		font-weight: 600;
		position:relative;
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light;
		margin-bottom:40px;
		-webkit-animation-delay: 1s;
		animation-delay: 1s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
		
	}
	.readmore  {
		-webkit-animation-delay: 1.5s;
		animation-delay: 1.5s;
		 -webkit-animation-duration: 0.6s;
		animation-duration: 0.6s;
		a {
			@include pos-transition(300ms);
			position:relative;
			font-size:12px;
			color:$light; 
			line-height:30px;
			text-transform:uppercase;
			font-weight:600;
			display:inline-block;
			&:after {
				content:"";
			    background: url(../img/links_static.png) 100% 50%;
				font-size: 0;
				width: 44px;
				height: 11px;
				display: inline-block;
				margin-left:25px;
				vertical-align:-2px;
			}
			&:hover {
				color:$main;
				@include pos-transition(300ms);
				&:after {
					 background-position: 0 50%;
					 -webkit-animation: passing 1200ms linear infinite;
					-moz-animation: passing 1200ms linear infinite;
					-ms-animation: passing 1200ms linear infinite;
					-o-animation: passing 1200ms linear infinite;
					animation: passing 1200ms linear infinite;
				}
			}
		}
	}
}
.desc2  {
	padding-left:100px;

	.title1 {
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light; 
		font-weight:600;
		text-transform:capitalize;
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
	}
	.title2{
		position:relative;
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light; 
		line-height:1;
		font-weight: 600;
		-webkit-animation-delay: 0.5s;
		animation-delay: 0.5s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
		span {
			color:$main;
		}
		
	}
	.title3{
		font-weight: 600;
		position:relative;
		font-size:60px;
		@media (min-width:1920px) {
			font-size:80px;
		}
		color:$light;
		margin-bottom:40px;
		-webkit-animation-delay: 1s;
		animation-delay: 1s;
		 -webkit-animation-duration: 0.9s;
		animation-duration: 0.9s;
		
	}
	.readmore  {
		-webkit-animation-delay: 1.5s;
		animation-delay: 1.5s;
		 -webkit-animation-duration: 0.6s;
		animation-duration: 0.6s;
		a {
			@include pos-transition(300ms);
			position:relative;
			font-size:12px;
			color:$light; 
			line-height:30px;
			text-transform:uppercase;
			font-weight:600;
			display:inline-block;
			&:after {
				content:"";
			    background: url(../img/links_static.png) 100% 50%;
				font-size: 0;
				width: 44px;
				height: 11px;
				display: inline-block;
				margin-left:25px;
				vertical-align:-2px;
			}
			&:hover {
				color:$main;
				@include pos-transition(300ms);
				&:after {
					 background-position: 0 50%;
					 -webkit-animation: passing 1200ms linear infinite;
					-moz-animation: passing 1200ms linear infinite;
					-ms-animation: passing 1200ms linear infinite;
					-o-animation: passing 1200ms linear infinite;
					animation: passing 1200ms linear infinite;
				}
			}
		}
	}
}
//fix responsive
@media (max-width: 1199px) {
	.desc1  {
		padding-left:get1024-vw(100px);
		.title1 {
			 font-size:get1024-vw(50px);
		}
		.title2{
			font-size:get1024-vw(50px);
		}
		.title3{
			font-size:get1024-vw(50px);
			margin-bottom:get1024-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get1024-vw(14px);
				line-height:get1024-vw(30px);
			}
		}
	}
	.desc2  {
		padding-left:get1024-vw(100px);
		.title1 {
			 font-size:get1024-vw(50px);
		}
		.title2{
			font-size:get1024-vw(50px);
		}
		.title3{
			font-size:get1024-vw(50px);
			margin-bottom:get1024-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get1024-vw(14px);
				line-height:get1024-vw(30px);
			}
		}
	}
}
//max 767
@media (max-width: 767px) {
	.desc1  {
		.title1 {
			 font-size:get767-vw(50px);
		}
		.title2{
			font-size:get767-vw(50px);
		}
		.title3{
			font-size:get767-vw(50px);
			margin-bottom:get767-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get767-vw(13px);
				line-height:get767-vw(30px);
			}
		}
	}
	.desc2  {
		.title1 {
			 font-size:get767-vw(50px);
		}
		.title2{
			font-size:get767-vw(50px);
		}
		.title3{
			font-size:get767-vw(50px);
			margin-bottom:get767-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get767-vw(13px);
				line-height:get767-vw(30px);
			}
		}
	}
}
//max 480
@media (max-width:480px) {
	.desc1  {
		.title1 {
			 font-size:get480-vw(30px);
		}
		.title2{
			font-size:get480-vw(30px);
		}
		.title3{
			font-size:get480-vw(30px);
			margin-bottom:get480-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get480-vw(14px);
				line-height:get480-vw(30px);
			}
		}
	}
	.desc2  {
		.title1 {
			 font-size:get480-vw(30px);
		}
		.title2{
			font-size:get480-vw(30px);
		}
		.title3{
			font-size:get480-vw(30px);
			margin-bottom:get480-vw(20px);				
		}
		.readmore  {
			a {
				font-size:get480-vw(14px);
				line-height:get480-vw(30px);
			}
		}
	}
}
	/* end responsive slideshow */
/* end module slideshow */
/* module tabcateslider */
.tab-category-container-slider {
	@media(max-width: 767px){
		margin-bottom:0;
	}
	.tab_cates {
		margin-top:60px;
		@media(max-width: 767px){
			margin-top:30px;
			li {
				margin-bottom:10px;
			}
		}
		li {
			position:relative;
			display:inline-block;
			line-height:18px;
			padding:0 25px;
			border-left:1px solid #e1e1e1;
			text-transform:uppercase;
			cursor:pointer;
			color:#2e2e2e;

			font{
				font-size:12px;
				font-weight:600;
			}

			@include pos-transition(300ms);
			&:first-child {
				border-left:0;
			}
			&.active,&:hover {
				color:$main;
				@include pos-transition(300ms);
				
			}
			
		}

	}
} 
/* end module tabcateslider */

/* module newletter */

.ft_newsletter {
	text-align:center;
	background: #f2f2f2;
	.pos_title {
		background:transparent;
	}
	.container {
		//background: url(../img/bg_newletter.jpg) no-repeat 0 100%;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		padding-top:95px;
		padding-bottom:95px;
		@media (max-width: 1199px) {
			padding-top:30px;
			padding-bottom:30px;
		}
		@media (max-width: 767px) {
			background: #f2f2f2;
		}
	}
	.desc{
	    color: #606060;
		font-size: 12px;
		letter-spacing: 0.2em;
		line-height: 25px;
		padding-bottom: 60px;
	}
	form{
		position: relative;
		overflow: hidden;
		.input_txt{
			display: inline-block;
			border: $main_border;
			background: $light;
			padding: 10px 140px 10px 10px;
			line-height: 30px;
			height: 50px;
			border-radius: 0;
			color:#8f8f8f;
			font-size: 12px;
			width:100%;
			@media (min-width: 768px) and (max-width: 1199px) { // min 768px max 1199px
				margin-bottom: 10px;
				margin-right: 0;
			}
		}
		.btn{
			height:50px;
			line-height:36px;
			font-size:14px;
			text-align:center;
			position:absolute;
			background:#2e2e2e;
			color:$light;
			top:0;
			right:0;
			font-weight:500;
			padding:0 35px;
			text-transform: capitalize;
			letter-spacing:0.025em;
			display: inline-block;
			vertical-align: -3px;
			@include pos-transition(300ms);
			&:hover {
				background:$main;
				@include pos-transition(300ms);
			}
		}
		.condition, .alert{
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 24px;
			color: #bcbcbc;
			background: none;
			border: 0;
			padding: 0;
			&.alert-danger{
				color: $main;
			}
			&.alert-success{
				color: $main;
			}
		}
	}
}

/* end module newletter */
/* module logo */
.pos_logo  {
	border-bottom: 1px dashed rgba(255,255,255,0.1); 
	.container {
		padding:110px 0;
		@media (max-width:767px) {
			padding:30px 0;
		}
	}
	.item_logo {
		padding:10px 0;
	}
	img {
		margin:0 auto;
		@include pos-transform(1);
		@include pos-transition (300ms);
		&:hover{
			@include pos-transform(1.05);
			@include pos-transition (300ms);
		}
	}
	
}

/* end module logo */
/*intagram_home  */
#instagram_block {
	.item-instagram {
		> a {
			display:block;
			position:relative;
			&:hover {
				&:before {
					@include pos-transform(1);
					opacity:1;
				}
				&:after {
					opacity:1;
					-webkit-animation: zoomIn 500ms ease-in-out;
					-moz-animation: zoomIn 500ms ease-in-out;
					-ms-animation: zoomIn 500ms ease-in-out;
					animation: zoomIn 500ms ease-in-out;
				}
			}
			&:before {
				content:"";
				position:absolute;
				top:10px;
				left:10px;
				right:10px;
				bottom:10px;
				background-color: rgba(0, 0, 0, 0.5);
				@include pos-transform(1.2);
				@include pos-transition(500ms);	
				opacity:0;
			}
			&:after {
				content:"";
				background: url(../img/line_small.png) no-repeat;
				width:20px;
				height:20px;
				position:absolute;
				top:50%;
				left:50%;
				margin-top:-10px;
				margin-left:-10px;
				opacity:0;
				@include pos-transition(500ms);	
			}
				
		}
	}
	.our_instagram {
	    display: inline-block;
		width: 100%;
		margin-top: 20px;
		@media (max-width: 767px) {
			margin-bottom:20px ;
		}
		a {
			font-size:12px;
			    color: #8d8d8d;
			font-style:italic;
			display:inline-block;
			&:hover {
				color:$main;
				span {
					text-decoration:underline;
				}
			}
			&:after {
				content: "\f101";
				font-family:$font-icon;
				font-size: 13px;
				margin:0 10px;
				color:$main;
				display:inline-block;
				font-style: normal;
				text-decoration: none;
				vertical-align: middle;
			}
		}
	}
}

#instagram_block_home {
	padding:60px 0;
	@media (min-width:1920px) {
		padding:120px 0;
	}
	//background: url(../img/bg_intagram.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	margin:0;
	.row {
		margin:0;
		
	}
	.owl-carousel .owl-item {
		padding:0;
	}
	.title_instagram {
		margin-bottom:60px;
		h2 {
			font-size:48px;
			@media (max-width:767px) {
				font-size:30px;
			}
			font-weight:600;
			text-transform:capitalize;
			color:$light;
			margin-bottom:30px;
			direction:ltr;
			span {
				color:$main;
			}
		}
		.des_intagram {
			span {
				color:$light;
				opacity:0.5;
				font-size:14px;
				@media (max-width: 767px) {
					font-size:12px;
				}
				letter-spacing:0.2em;
				margin:0;
			}
			a {
				display:block;
				float:right;

				@media (max-width:479px) {
					float:left;
					margin-top:5px;
				}

				font-size:12px;
				color: $light;
				text-transform:uppercase;

				&:hover {
					color:$main;
					span {
						text-decoration:underline;
					}
				}

				&:after {
					content: "\f101";
					font-family:$font-icon;
					font-size: 13px;
					margin:0 10px;
					display:inline-block;
					font-style: normal;
					text-decoration: none;
					vertical-align: middle;
				}
			}
		}
	}
	.text-bottom {
		text-align:center;
		margin-top:60px;
		span {
			color:#adadad;
			font-size:12px;
			font-weight:600;
			text-transform:uppercase;
			display:inline-block;
			padding:0 20px;
			position:relative;
			&:before {
				content:"";
				width:5000%;
				border-bottom: 1px solid rgba(255, 255, 255, .1);
				position:absolute;
				top:50%;
				right:100%;
			}
			&:after {
				content:"";
				width:5000%;
				border-bottom: 1px solid rgba(255, 255, 255, .1);
				position:absolute;
				top:50%;
				left:100%;
			}
		}
	}
	.item-instagram {
		a {
			display:block;
			position:relative;
			&:hover {
				&:before {
					@include pos-transform(1);
					opacity:1;
				}
				&:after {
					opacity:1;
					-webkit-animation: zoomIn 500ms ease-in-out;
					-moz-animation: zoomIn 500ms ease-in-out;
					-ms-animation: zoomIn 500ms ease-in-out;
					animation: zoomIn 500ms ease-in-out;
				}
			}
			&:before {
				content:"";
				position:absolute;
				top:25px;
				left:25px;
				right:25px;
				bottom:25px;
				background-color: rgba(0, 0, 0, 0.5);
				@include pos-transform(1.2);
				@include pos-transition(500ms);	
				opacity:0;
			}
			&:after {
				content:"";
				background: url(../img/line.png)  no-repeat;
				width:68px;
				height:68px;
				position:absolute;
				top:50%;
				left:50%;
				margin-top:-34px;
				margin-left:-34px;
				opacity:0;
				@include pos-transition(500ms);	
			}
				
		}
	}
}
/*end intagram _home  */

/* static block */
.our_story {
	position:relative;
	text-align:center;
	padding:125px 0;
	&:before {
		content:"";
		width:1px; 
		height:85px;
		background:#d6d7d9;
		position:absolute;
		top:0;
		left:50%;
	}
	.title1 {
		font-size:24px;
		color:#2e2e2e;
		font-weight:700;
		letter-spacing:0.5em;
		text-transform:uppercase;
		
	}
	.desc_our {
		font-size:12px;
		color:#606060;
		letter-spacing:0.2em;
		line-height:25px;
		padding:50px 100px;
		@media (max-width:767px){
			padding:30px;
		}
	}
	.title2 {
		font-size:24px;
		color:#2e2e2e;
		font-family: 'Satisfy', cursive;
		line-height:35px;
		margin-bottom:45px;
	}
}
.banner-home {
	margin-bottom:80px;
	margin-top:30px;

	position:relative;
	text-align:center;
	z-index:8;

	.container {
		background:$light;
		@media(min-width:1200px){
			max-width:1230px;
			padding:30px;
			padding-bottom:0;
			-webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
			box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
		}
	}
	.banner-box {
		.content2 {
			&:before {
				content: "";
				width: 140px;
				height: 50px;
				border: 5px solid $light;
				border-bottom: 0;
				position: absolute;
				top: -25px;
				left: 50%;
				pointer-events: none;
				-moz-transform: translateX(-50%) translateY(-100%);
				-webkit-transform: translateX(-50%) translateY(-100%);
				-o-transform: translateX(-50%) translateY(-100%);
				-ms-transform: translateX(-50%) translateY(-100%);
				transform: translateX(-50%) translateY(-100%);
			}
			&:after {
				content: "";
				width: 140px;
				height: 50px;
				border: 5px solid $light;
				border-top: 0;
				position: absolute;
				bottom: -25px;
				left: 50%;
				pointer-events: none;
				-moz-transform: translateX(-50%) translateY(100%);
				-webkit-transform: translateX(-50%) translateY(100%);
				-o-transform: translateX(-50%) translateY(100%);
				-ms-transform: translateX(-50%) translateY(100%);
				transform: translateX(-50%) translateY(100%);
			}
			@media (min-width:768px) and (max-width:991px){
				&:before {
					height:30px;
					top:-20px;
				}
				&:after {
					height:30px;
					bottom:-20px;
				}				
						
			}			
			a {
				padding:0 30px;
				margin-top:15px;
				font-size:12px;
				text-transform:uppercase;
				font-weight:600;
				color:$light;
				&:after {
					content:"";
						background: url(../img/links_static.png) 100% 50% no-repeat;
						width: 44px;
						height: 11px;
						display: inline-block;
						margin:0 10px;
						vertical-align: -2px;
				}
				@include pos-transition (300ms);	
				&:hover {
					color:$main;
					@include pos-transition (300ms);
					&:after {
						background-position:0 50%; 
						@include pos-transition (300ms);
					}		
				}
			}
		}
		.content3 {
			padding:50px 20px;
			left:0;
			right:0;
			min-width:auto;
			-moz-transform: translateX(0) translateY(-50%);
			-webkit-transform: translateX(0) translateY(-50%);
			-o-transform: translateX(0) translateY(-50%);
			-ms-transform: translateX(0) translateY(-50%);
			transform: translateX(0) translateY(-50%);
			@media (min-width:768px) and (max-width:991px){			
				left:0;
				right:0;	
			}
			&:before {
				content: "";
				width:40px;
				height: 3px;
				background:$light;
				position: absolute;
				top: 25px;
				left: 50%;
				pointer-events: none;
				-moz-transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
			}
			&:after {
				content: "";
				width:40px;
				height: 3px;
				background:$light;
				border-bottom: 0;
				position: absolute;
				position: absolute;
				bottom: 25px;
				left: 50%;
				pointer-events: none;
				-moz-transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
			}	
		
			h4 {
				font-size:18px;
			}
		}
	}
	
}

.banner-box {
	overflow: hidden;
	position: relative;
	display: block;
	margin-bottom:30px;
	@media(max-width:991px){
		margin-bottom:30px;
	}
	&:hover {
		.banner_content {
			.des_banner, .title_banner,a.links,h4,a{
				-webkit-animation: fadeInUp 500ms ease-in-out;
				-moz-animation: fadeInUp 500ms ease-in-out;
				-ms-animation: fadeInUp 500ms ease-in-out;
				animation: fadeInUp 500ms ease-in-out;
			}
		}
		.links  a{
			-webkit-animation: fadeInUp 500ms ease-in-out;
			-moz-animation: fadeInUp 500ms ease-in-out;
			-ms-animation: fadeInUp 500ms ease-in-out;
			animation: fadeInUp 500ms ease-in-out;
		}
	}
	
	.banner_content {
		position:absolute;
		top:50%;
		left:50%;
		padding:0 30px;
		@media (min-width:768px) and (max-width:991px){
			padding:0 10px;
			
		}
		min-width:100%;
		-moz-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		z-index:2;
		.title_banner,h4 {
			font-size:24px;
			@media (min-width:768px) and (max-width:1199px){
				font-size:20px;
			}
			color:$light;
			text-transform:uppercase;
			font-weight:700;
			line-height:30px;
			margin-bottom:5px;
		}
		.des_banner {
			font-size:14px;
			color:$light;
			line-height:25px;
			font-weight:300;
			letter-spacing:0.025em;
		}
		
	}
	.links  a{
		position:absolute;
		bottom:30px;
		left:0;
		right:0;
		padding:0 30px;
		@media (min-width:768px) and (max-width:991px){
			padding:0 10px;
			
		}
		font-size:12px;
		text-transform:uppercase;
		font-weight:600;
		color:$light;
		&:after {
			content:"";
				background: url(../img/links_static.png) 100% 50% no-repeat;
				width: 44px;
				height: 11px;
				display: inline-block;
				margin:0 10px;
				vertical-align: -2px;
		}
		@include pos-transition (300ms);	
		&:hover {
			color:$main;
			@include pos-transition (300ms);
			&:after {
				background-position:0 50%; 
				@include pos-transition (300ms);
			}		
		}
	}
	a{
		position: relative;
		display: block;
		position:relative;
	
	}
	&:hover img{
		@include pos-transform(1.05);
		@include pos-transition (500ms);
	}
	img{
		width:100%;
		@include pos-transition (500ms);	
		
	}	
}

.static-info {
	margin-bottom:80px;	
	@media(max-width:767px){
		margin-bottom:0;
	}
	.info {
		text-align:center;
		padding:30px 10px;
		@include pos-transition (300ms);
		position:relative;
		@media(max-width:767px){
			margin-bottom:30px;
		}
		&:before{
			content:"";
			height:4px;
			width:237px;
			position:absolute;
			left:50%;
			-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			bottom:-24px;
			display:inline-block;
			background: url(../img/box-shaw-static.png) no-repeat;
			opacity:0;
			@include pos-transition (300ms);
		}
		h3 {
			color:$light;
			font-size:14px;
			@media (min-width:768px) and (max-width:991px){
				font-size:11px;
				
			}
			letter-spacing:0.1em;
			font-weight:700;
			text-transform:uppercase;
		}
		&.info1 {
			background: url(../img/shipping.png) 100% 100%  #a2afc6 no-repeat;
		}
		&.info2{
			background: url(../img/headphone.png) 100% 100%  #c6bba2 no-repeat;
		}
		&.info3 {
			background: url(../img/payment.png) 100% 100%  #a2c6c0 no-repeat;
		}
		&:hover {
			-moz-transform: translateY(10px);
			-webkit-transform: translateY(10px);
			-o-transform: translateY(10px);
			-ms-transform: translateY(10px);
			transform: translateY(10px);
			@include pos-transition (300ms);
			&:before{
				opacity:1;
				@include pos-transition (300ms);
			}
		}
	}
}
.static-bottom  {
	.banner-box  {
		margin-bottom:0;
	}
}
/* end static block */
/* blog */
.home_blog_post_area {
	position:relative;
	display:inline-block;
	width:100%;
	//background: url(../img/bg_smartblog.jpg)  no-repeat;
	padding-top:100px;
	margin-bottom:80px;
	.owl-carousel .owl-wrapper-outer {
		padding-bottom: 3px;
	}
	.pos_title {
		&:before {
			content: url(../img/icon_title_blog.png);
		}
		a {
			color:$light;
		}	
	}
	
	.blog_slider .item{
		.blog_post {
			display:inline-block;
			width:100%;
			background:$light;
			padding:15px;
			-webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.18);
			box-shadow: 0 0 8px 0 rgba(0,0,0,0.18);
			img{
				width: 100%;
			}
			.blog_post_content_top {
				margin-bottom:30px;
			}
			.post_title {
				display: block;
				font-size: 14px;
				color: #2e2e2e;
				text-transform: uppercase;
				font-weight: 600;
				line-height: 25px;
				position: relative;
				margin-bottom: 15px;
				&:hover {
					color:$main;
				}
			}
			.post_meta{
				overflow: hidden;
				margin-bottom:20px;
				p{
					margin:0 10px;
					display: block;
					display:inline-block;
					color: #9d9d9d;
					font-size: 12px;
					line-height: 15px;
					i{
						padding-right: 5px;
					}
				}
			}
			.post_description{
				line-height: 25px;
				font-size: 12px;
				color: #606060;
				padding-bottom: 25px;
				margin:0;
			}
		}
	}
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail {
    margin-bottom: 30px;
    position: relative;
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask {
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color: rgba(0,0,0,0.4);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask .blog_mask_content {
    position: absolute;
    left: 110%;
    top: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask .blog_mask_content a {
    display: block;
    text-align: center;
    font-size: 70px;
    color: #fff;
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask .blog_mask_content {
    left: 50%;
}
/* end blog */


/* category page */
@media (max-width: 767px) {
	#left-column ,#content-wrapper,	#right-column  {
		width: 100%;
	}
}

#js-product-list-top {
	ul.display  {
		float:left;
		margin-right:50px;
		position:relative;
		z-index:1;
		@media (min-width: 768px) and (max-width: 991px) {
			margin-right:20px;
		}
		@media (max-width: 767px) {
			margin-bottom:10px;
		}
		li {
			float:left;
			height:27px;
			width:27px;
			line-height:27px;
			border:$main_border;
			margin-right:5px;
			cursor:pointer;
			color: #cccccc;
			text-align:center;
			@include pos-transition(300ms);
			&:hover, &.selected{
				color:#2e2e2e;
				border-color:#2e2e2e;
				@include pos-transition(300ms);
			}
			i {
				font-size:18px;
			}
		}
	}
}
.grid-page  #grid,.list-page #list {
	color:#2e2e2e !important;
	border-color:#2e2e2e !important;
	@include pos-transition(300ms);
}
.quickview .modal-content {
    background:$light;
}
#js-product-list {
	.product_content {
		overflow:hidden;
		position:relative;
		&.list {
			.js-product-miniature {
			    background:$light;
				-webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
				box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
				margin-bottom:30px;
				@media (min-width: 480px) {
					.row {
						margin:0;
					}
				}
				.img_block  {
					padding:0;
					.add-to-links,.product-price-and-shipping{
						display:none;
					}
				}
				.product_desc  {
					padding-left:30px;
					position:static;
					.add-to-links {
						position:static; 
						margin:0;
						-moz-transform:none;
						-webkit-transform: none;
						-o-transform: none;
						-ms-transform:none;
						transform:none;
						li {
						    -webkit-animation: none;
							-moz-animation: none;
							-ms-animation: none;
							animation: none;
							opacity: 1;
							margin: 0 5px;
							margin-bottom:5px;
							display: inline-block;
							vertical-align: top;
							a {
								border: 2px solid #ebebeb;
								line-height: 40px;
								height: 40px;
								width: 40px;
								&.ajax_add_to_cart_button {
									border: 0;
									line-height: 40px;
									height: 40px;
									width: auto;
									background-color: #2e2e2e;
									font-size: 12px;
									font-weight: 700;
									color: #fff;
									text-transform: uppercase;
									border-radius: 25px;
									padding: 0 20px 0 45px;
									background-position: -30px 0;
									&:hover {
										background-color:$main;
									}
								}
								&:hover {
									border-color:$main;
								}
							}
						}
					}
					.product-price-and-shipping {
					    font-family: 'Lato', sans-serif;
						margin-top: 15px;
						position:static;
						box-shadow:none;
						padding:0;
						height:auto;
						.price {
							color: #343940;
							font-weight: 700;
							font-size: 18px;
						}
						.regular-price {
						    color: #959595;
							font-size: 16px;
							text-decoration: line-through;
							margin: 0 5px;
							font-weight: 500;
						}
						.discount-percentage {
							display:none;
						}
					}
					.product_name {
					    color: #2e2e2e;
						font-size: 18px;
						font-weight: 500;
						padding: 0;
					}
					.product-desc p {
						display:block;
						margin: 0 0 15px 0;
					}
					.variant-links {
						margin-top:30px;
					}
		
				}
				@media (max-width: 479px) {
					.img_block ,.product_desc {
						width:100%;
					}
				}
			}
		}
		&.grid {
			.item-product {
				@media (min-width: 480px) and (max-width: 543px) {
					width:50%;
				}
			}
			.js-product-miniature {
				margin-bottom:30px;
			}
			@media (min-width: 992px) {
				.item-product:nth-child(3n+1)   {
					clear:both;
				}
			}
			@media (max-width: 991px) {
				.item-product:nth-child(2n+1)  {
					clear:both;
				}
			}
			@media (max-width: 479px) {
				.item-product  {
					clear:both;
				}
			}
		}
	}
}

.owl-item.last-active .js-product-miniature{ 
	border-right:1px solid transparent;
}
@media (min-width:480px) and (max-width:543px) {
	#js-product-list .product_content .item-product.grid  {
		float:left;
		width:50%;
	}
}
 .inner-wrapper {
	padding-top:80px;
	@media (max-width: 767px) {	
		padding-top:30px;
	}
}
#index .inner-wrapper {
	padding-top:0;
}

.breadcrumb_container{
	overflow: hidden;
	text-align:center;
	padding:0 0 55px 0;

	.breadcrumb {
		background: transparent;
		padding: 0;
		margin: 0;
		ol {
			padding-left: 0;
			margin-bottom: 0;
			li {
				display: inline;
				&:after{
					content: "/";
					color: #adadad;
					margin: 0.3125em;
					font-size:10px;
				}
				&:last-child {
					a ,span{
						color:$main;
					}
					&:after{
						content: "";
					}
				}
				a{
					display: inline-block;
					position: relative; 
					color: #adadad;
					line-height:25px;
					&:hover{
						color: $main;
					}
				}
			}
		}
	}
	.breadcrumb[data-depth="1"] {
		display: none;
	}

	body.page-product &{
		margin-top: 40px;
	}
}

.block-category {
    margin-bottom: 0;
	display:inline;
	padding:0;
	min-height:auto;
	&.card {
		box-shadow: none;
		border:0;
	}

}
.block-category .category-cover {
	position:static;
	img {
		width:100%;
		height:auto;
	}
}
#products img, .featured-products img, .product-accessories img{
	margin: 0;
}
.block-categories, #search_filters{
	box-shadow: none;
    background: #fff;
    padding:15px;
	-webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    margin-bottom: 30px;
}
.block-categories{
	a.h6{
		position: relative;
		display: block;
		color: #363636;
		padding-bottom: 10px;
		margin: 0;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 10px;
		border-bottom: 2px solid #363636;
	}
	.category-sub-menu {
		margin:0;
		li {
			a{
				color: #797979;
				&:hover{
					color: $main;
				}
			}
		}
	}
	.category-sub-menu li[data-depth="0"] > a{
		font-weight: normal;
		color: #797979;
		display: block;
		font-size: 14px;
		text-transform: capitalize;
		line-height: 50px;
		margin:0;
		padding:0;
		border-bottom: 1px dashed #ebebeb;
	}
	.category-sub-menu li[data-depth="1"] {
		margin:0;
	}
	.category-sub-menu .category-sub-link {
		font-size:12px;
		line-height: 50px;
		margin:0;
		padding:0;
		display:block;
		border-bottom: 1px dashed #ebebeb;
		
	}
	.collapse-icons {
		top: 15px;
	}

}

#search_filters_wrapper #search_filters h4{
	position: relative;
	display: block;
	color: #363636;
	padding-bottom: 10px;
	margin: 0;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 10px;
	border-bottom: 2px solid #363636;
	
}
#search_filters .facet {
	padding-top: 25px;
	.facet-title{
		font-size: 14px;
		text-transform: uppercase;
		color:#363636;
		font-weight: 700;
		line-height:24px;
	}
	.collapse{
		margin: 0;
		li {
			line-height:25px;
			border-bottom: 1px dashed #ebebeb;
		}
	}
	.custom-checkbox input[type="checkbox"] + span{
		margin: -3px 3px 0 0;
		width: 15px;
		height: 15px;
		border: 1px #dadada solid;
		&.color{
			border: 0;
		}
	}
	.facet-label {
		margin: 10px 0;
		a{
			color: #797979;
			font-size: 12px;
			margin-top:0;
			&:hover{
				color: $main;
			}
		}
	}
}
@media (max-width: 767px) {
	#category #left-column #search_filters .facet .navbar-toggler {
		padding: 0.625rem 3rem 0 0;
	}
}
.products-selection .filter-button .btn-secondary, .products-selection .filter-button .btn-tertiary {
    box-shadow: none;
    padding: 2px 15px;
    border-radius: 4px;
}
.products-selection .total-products {
    padding:0;
}
.products-selection{
	margin-bottom: 0;
	background:$light;
	padding:15px;
	-webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
	margin-bottom:30px;
	p, .sort-by{
		line-height: 21px;
		font-size: 12px;
	    padding: 3px 15px;
		margin:0;
		float:left;
		color: #616161;
	}
	.products-sort-order .select-title{
		border:$main_border;
		padding: 0 15px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		line-height: 24px;
		font-size: 12px;
		margin:0;
		color: #616161;
	}
	.products-sort-order .dropdown-menu{
		background: #fff;
		border: 1px solid #e1e1e1;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		right: 15px;
		left: auto;
		.select-list{
			line-height: 24px;
			font-size: 14px;
			padding: 3px 15px;
			&:hover{
				background: $main;
			}
		}
	}
}
.pagination{
	font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 15px;
	background:$light;
	-webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
	box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
	margin-bottom:50px;
	a{
		font-weight: 400;
		color: $dark;
		padding: 0 5px;
		font-size:14px;
		i {
			font-size:18px;
		}
		&:hover{
			color: $main;
		}
	}
	.disabled{
		color: $dark;
	}
	.current a{
		color: $main;
	}
}
/* end category page */

/* product page */
.h1.namne_details,.product_name_h1 {
    color: #2e2e2e;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 20px;
}
.product-prices {
    font-family: 'Lato',sans-serif;
}
.product-discount {
    display:inline-block;
}
.product-discount .regular-price {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    display: inline-block;
    color: #959595;
	
}
.has-discount.product-price, .has-discount p {
	font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    display: inline-block;
    color: #343940;
}
.has-discount .discount {
    text-transform: uppercase;
    display: inline-block;
    background: #343434;
    color: white;
    font-size: 12px;
    padding: 0 15px;
    text-align: center;
    z-index: 1;
    line-height: 30px;
    border-radius: 20px;
    font-weight: 500;
    height: 30px;
}
.product-information {
    color: #acacac;
    font-size: 12px;
    font-weight: normal;
	span {
		font-style:italic;
	}
	.product-desc p{
		line-height: 27px;
		font-size: 12px;
		color: #6f6f6f;
		margin: 0;
		font-style: normal;
	}
}

.product-quantity .qty {
    margin-right: 20px;
}
.product-actions .add-to-cart {
	position: relative;
    border: 0;
    line-height: 41px;
    height: 40px;
    width: auto;
    background: url(../img/button1.png) 0 0 white no-repeat;
    background-color: #2e2e2e;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 0 20px 0 45px;
    background-position: -30px 0;
	box-shadow: none;
	&:hover {
		background-color:$main;
	}
}
#product #block-reassurance {
	box-shadow: none;
	margin-top:20px;
	span {
	    font-weight: 400;
		font-size: 12px;
		font-style: normal;
	}
	li .block-reassurance-item {
		padding: 15px 0;
	}
}
#product #content {
	overflow:hidden;
}
.product-cover img {
    box-shadow:none; 
	border:$main_border;
}
li.product-flag {
	background: $main;
    color: white;
    font-size: 12px;
    padding: 0;
    min-width: 60px;
    text-align: center;
    display: block;
    z-index: 1;
    line-height: 30px;
    border-radius: 20px;
    font-weight: 500;
    height: 30px;
	margin:0;
}
.social-sharing li {
    box-shadow:none;
    height:auto;
    width: auto;
    border-radius:0;
    display: inline-block;
    background-color: transparent;
	a {
		display: block;
		width:40px;
		height:40px;
		border-radius:100%;
		line-height:40px;
		text-align:center;
		border:$main_border;
		color:#b8b8b8;
		background:$light;
		white-space: normal;
		text-indent:0;
		overflow: hidden;
		&:hover {
			background:$main;
			color:$light;
			border-color:$main;
		}
	}
}
#main {
    background:$light;
    position: relative;
    padding: 30px;
	margin-bottom:30px;
    -webkit-box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
    box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.05);
}
#category #main ,#index #main {
    background:transparent;
    padding: 0;
	margin:0;
    -webkit-box-shadow: none;
    box-shadow:none;
}
.tabs {
    box-shadow: none;
    background: transparent;
	margin-bottom:50px;
	padding:0;
	display: inline-block;
    width: 100%;
	.nav-tabs {
		border: none;
		margin-bottom: 0;
		float: left;
		min-width:170px;
		@media (max-width:767px) {
			float:none;
			display:inline-block;
			width:100%;
			overflow:visible;
		}
		.nav-item {
			float: none;
			margin: 0;
			display: block;
    		width: 100%;

			.nav-link {
				position: relative;
				line-height: 25px;
				display:block;
				padding:10px 0;
				text-transform: uppercase;
				color: #bebebe;
				font-size: 14px;
				font-weight: 700;
				border:0;
				background:transparent;
				@include pos-transition(300ms);
				&:hover,&.active {
					color:#484848;
					@include pos-transition(300ms);
					&:before {
						background:#464646;
					}
				}
				&:before {
					content:"";
					width:2px;
					height:100%;
					background:#ebebeb;
					position:absolute;
					top:0;
					right:-2px;
				}
			}
		}
	}
}
.tab-content {
    overflow: hidden;
    padding:0 30px;
    background:transparent;
    border-left: 2px solid #ebebeb;
    font-size: 14px;
	@media (max-width: 767px) {
		overflow:visible;
		border: 2px solid #ebebeb;
		font-size: 14px;
		padding:30px;
	}
}
.product-description p {
	font-size: 12px;
    color: #898989;
    line-height: 30px;
    letter-spacing: 0.025em;
}
#tab-content {
	font-size:12px;
	label {
		font-size:12px;
	}
}
#product-modal .modal-content .modal-body .product-images img {
    max-width: none;
	border: transparent 3px solid;
	@include pos-transition(300ms);
	&:hover {
		@include pos-transition(300ms);
	}
}
#main .images-container .js-qv-mask {
	max-width: 90%;
	margin:auto;
	overflow: visible;
	margin-bottom: 50px;
}
.images-container {
	.product-images {
		.owl-item {
			padding:0 10px;
			
		}
		.owl-buttons > div {
			opacity:1;
			margin-top:-10px;
			width: 20px;
			height: 20px;
			line-height: 20px;
			&.owl-prev {
				left:0;
			}
			&.owl-next {
				right:0;
			}
		}
	}
}	
.thumb-container {
	img {
		width:100%;
		height:auto;
		cursor:pointer;
		border:$main_border;
		margin:0;
		@include pos-transition(300ms);
		&.selected,&:hover {
			border-color: #484848;
			@include pos-transition(300ms);
		}
	}
}
.modal-content {
	.thumb-container {
		img {
			margin-bottom:10px;
		}
	}
}
.product-cover .layer {
    background: rgba(0, 0, 0, 0.3);
	@include pos-transition(300ms);
}
.product-accessories ,.categoryproducts{
	margin-bottom:50px;
}
/* end product page */

/* responsive */

@media (max-width:767px) {
	.blockcart {
		display:inline-block;
		width:100%;
		float:none;
		margin:0;
		text-align:center;
		.body {
			right:50%;
			-moz-transform: translateX(50%);
			-webkit-transform: translateX(50%);
			-o-transform: translateX(50%);
			-ms-transform: translateX(50%);
			transform: translateX(50%);
		}
	}
	#header .social_follow {
		float: none;
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-top: 15px;
	}
}
@media (max-width:543px) {
	.products-selection .filter-button {
		padding:0 15px;
		width: 100%;
	}
	#js-product-list-top .products-sort-order {
		width:100%;
		margin-bottom:10px;
	}
	
}
@media (max-width:479px) {
	.blockcart {
		width:100%;
	}
	
}
/* end responsive */
.blog_post .slick-prev, .blog_post .slick-next {
    line-height: 45px;
}

/* end edit theme */
/* rtl theme */

.lang-rtl main {
    text-align: right !important;
}
.lang-rtl * {
    direction: rtl !important;
}
body#checkout.lang-rtl section.checkout-step .radio-block {
	text-align: right!important;
}
body#checkout.lang-rtl section.checkout-step.-reachable.-complete h1 .step-edit {
	float: left
}
body#checkout.lang-rtl section.checkout-step .payment-options .custom-radio {
	margin-right: 0!important;
	margin-left: 1.25rem!important
}
body#checkout.lang-rtl section.checkout-step .payment-options .custom-radio,
body#checkout.lang-rtl section.checkout-step .payment-options label {
	float: right!important;
}
body#checkout.lang-rtl .condition-label,
body.lang-rtl #product-availability {
	margin-left: 0!important;
}
body#checkout.lang-rtl .condition-label {
	margin-right: 2.5rem!important;
}
body.lang-rtl .pull-xs-left,
body.lang-rtl .product-quantity .qty,
body.lang-rtl .product-actions .add-to-cart {
	float: right!important;
}
body.lang-rtl .product-quantity .qty {
	margin-right: 0!important;
	margin-left: 20px!important;
}
body.lang-rtl #product-availability {
	margin-right: 0.625rem!important;
}
body.lang-rtl .product-quantity .add {
	float: right!important;
}

body.lang-rtl .social-sharing ul {
	margin-right: 10px!important;
}
body.lang-rtl .page-my-account #content .links {
	-webkit-box-pack: end!important;
	-moz-box-pack: end!important;
	box-pack: end!important;
}
body#checkou.lang-rtl section.checkout-step #delivery-addresses,
body#checkout.lang-rtl section.checkout-step #invoice-addresses {
	-webkit-box-pack: end!important;
	-moz-box-pack: end!important;
	box-pack: end!important;
}
body.lang-rtl .products-sort-order .select-title,
body.lang-rtl input.form-control,
body.lang-rtl input[type=text],
body.lang-rtl .search-widget input[type=text]:focus + button .search,
body.lang-rtl textarea {
	text-align: right;
}
body.lang-rtl .form-group .form-control-label,
body.lang-rtl .form-group .col-md-6 {
	float: right!important;
	text-align: left!important;
}
body.lang-rtl .form-group .col-md-6.form-control-valign {
	text-align: right!important;
}
body.lang-rtl .form-group .form-control-comment {
	float: left!important;
}
body.lang-rtl .custom-checkbox label {
	float: right!important;
	text-align: right!important;
}
body.lang-rtl .form-group span.custom-checkbox span {
	float: right!important;
	margin-left: 10px!important;
}
body.lang-rtl .contact-rich .block .icon {
	float: right!important;
}
body.lang-rtl .quickview .social-sharing {
	margin-left: 0!important;
	margin-right: 2.5rem!important;
}
body.lang-rtl .header-top .search-widget form input[type="text"] {
	padding-right: 40px!important;
}

body.lang-rtl .footer-container .links .collapse,
body.lang-rtl .top-menu .collapse ul {
	padding-right: 0!important;
}
body.lang-rtl {
	.pull-xs-0 {
	  left: auto; }

	.pull-xs-1 {
	  left: 8.33333%; }

	.pull-xs-2 {
	  left: 16.66667%; }

	.pull-xs-3 {
	  left: 25%; }

	.pull-xs-4 {
	  left: 33.33333%; }

	.pull-xs-5 {
	  left: 41.66667%; }

	.pull-xs-6 {
	  left: 50%; }

	.pull-xs-7 {
	  left: 58.33333%; }

	.pull-xs-8 {
	  left: 66.66667%; }

	.pull-xs-9 {
	  left: 75%; }

	.pull-xs-10 {
	  left: 83.33333%; }

	.pull-xs-11 {
	  left: 91.66667%; }

	.pull-xs-12 {
	  left: 100%; }
	.push-xs-0 {
	  right: auto; }

	.push-xs-1 {
	  right: 8.33333%; }

	.push-xs-2 {
	  right: 16.66667%; }

	.push-xs-3 {
	  right: 25%; }

	.push-xs-4 {
	  right: 33.33333%; }

	.push-xs-5 {
	  right: 41.66667%; }

	.push-xs-6 {
	  right: 50%; }

	.push-xs-7 {
	  right: 58.33333%; }

	.push-xs-8 {
	  right: 66.66667%; }

	.push-xs-9 {
	  right: 75%; }

	.push-xs-10 {
	  right: 83.33333%; }

	.push-xs-11 {
	  right: 91.66667%; }

	.push-xs-12 {
	  right: 100%; }
	.offset-xs-1 {
	  margin-right: 8.33333%; }

	.offset-xs-2 {
	  margin-right: 16.66667%; }

	.offset-xs-3 {
	  margin-right: 25%; }

	.offset-xs-4 {
	  margin-right: 33.33333%; }

	.offset-xs-5 {
	  margin-right: 41.66667%; }

	.offset-xs-6 {
	  margin-right: 50%; }

	.offset-xs-7 {
	  margin-right: 58.33333%; }

	.offset-xs-8 {
	  margin-right: 66.66667%; }

	.offset-xs-9 {
	  margin-right: 75%; }

	.offset-xs-10 {
	  margin-right: 83.33333%; }

	.offset-xs-11 {
	  margin-right: 91.66667%; }
	  
	@media (min-width: 544px) {
	  .col-sm-1 {
		float: right;
		width: 8.33333%; }
	  .col-sm-2 {
		float: right;
		width: 16.66667%; }
	  .col-sm-3 {
		float: right;
		width: 25%; }
	  .col-sm-4 {
		float: right;
		width: 33.33333%; }
	  .col-sm-5 {
		float: right;
		width: 41.66667%; }
	  .col-sm-6 {
		float: right;
		width: 50%; }
	  .col-sm-7 {
		float: right;
		width: 58.33333%; }
	  .col-sm-8 {
		float: right;
		width: 66.66667%; }
	  .col-sm-9 {
		float: right;
		width: 75%; }
	  .col-sm-10 {
		float: right;
		width: 83.33333%; }
	  .col-sm-11 {
		float: right;
		width: 91.66667%; }
	  .col-sm-12 {
		float: right;
		width: 100%; }
	  .pull-sm-0 {
		left: auto; }
	  .pull-sm-1 {
		left: 8.33333%; }
	  .pull-sm-2 {
		left: 16.66667%; }
	  .pull-sm-3 {
		left: 25%; }
	  .pull-sm-4 {
		left: 33.33333%; }
	  .pull-sm-5 {
		left: 41.66667%; }
	  .pull-sm-6 {
		left: 50%; }
	  .pull-sm-7 {
		left: 58.33333%; }
	  .pull-sm-8 {
		left: 66.66667%; }
	  .pull-sm-9 {
		left: 75%; }
	  .pull-sm-10 {
		left: 83.33333%; }
	  .pull-sm-11 {
		left: 91.66667%; }
	  .pull-sm-12 {
		left: 100%; }
	  .push-sm-0 {
		right: auto; }
	  .push-sm-1 {
		right: 8.33333%; }
	  .push-sm-2 {
		right: 16.66667%; }
	  .push-sm-3 {
		right: 25%; }
	  .push-sm-4 {
		right: 33.33333%; }
	  .push-sm-5 {
		right: 41.66667%; }
	  .push-sm-6 {
		right: 50%; }
	  .push-sm-7 {
		right: 58.33333%; }
	  .push-sm-8 {
		right: 66.66667%; }
	  .push-sm-9 {
		right: 75%; }
	  .push-sm-10 {
		right: 83.33333%; }
	  .push-sm-11 {
		right: 91.66667%; }
	  .push-sm-12 {
		right: 100%; }
	  .offset-sm-0 {
		margin-right: 0%; }
	  .offset-sm-1 {
		margin-right: 8.33333%; }
	  .offset-sm-2 {
		margin-right: 16.66667%; }
	  .offset-sm-3 {
		margin-right: 25%; }
	  .offset-sm-4 {
		margin-right: 33.33333%; }
	  .offset-sm-5 {
		margin-right: 41.66667%; }
	  .offset-sm-6 {
		margin-right: 50%; }
	  .offset-sm-7 {
		margin-right: 58.33333%; }
	  .offset-sm-8 {
		margin-right: 66.66667%; }
	  .offset-sm-9 {
		margin-right: 75%; }
	  .offset-sm-10 {
		margin-right: 83.33333%; }
	  .offset-sm-11 {
		margin-right: 91.66667%; }
	} 
	@media (min-width: 768px) {
	  .col-md-1 {
		float: right;
		width: 8.33333%; }
	  .col-md-2 {
		float: right;
		width: 16.66667%; }
	  .col-md-3 {
		float: right;
		width: 25%; }
	  .col-md-4 {
		float: right;
		width: 33.33333%; }
	  .col-md-5 {
		float: right;
		width: 41.66667%; }
	  .col-md-6 {
		float: right;
		width: 50%; }
	  .col-md-7 {
		float: right;
		width: 58.33333%; }
	  .col-md-8 {
		float: right;
		width: 66.66667%; }
	  .col-md-9 {
		float: right;
		width: 75%; }
	  .col-md-10 {
		float: right;
		width: 83.33333%; }
	  .col-md-11 {
		float: right;
		width: 91.66667%; }
	  .col-md-12 {
		float: right;
		width: 100%; }
	  .pull-md-0 {
		left: auto; }
	  .pull-md-1 {
		left: 8.33333%; }
	  .pull-md-2 {
		left: 16.66667%; }
	  .pull-md-3 {
		left: 25%; }
	  .pull-md-4 {
		left: 33.33333%; }
	  .pull-md-5 {
		left: 41.66667%; }
	  .pull-md-6 {
		left: 50%; }
	  .pull-md-7 {
		left: 58.33333%; }
	  .pull-md-8 {
		left: 66.66667%; }
	  .pull-md-9 {
		left: 75%; }
	  .pull-md-10 {
		left: 83.33333%; }
	  .pull-md-11 {
		left: 91.66667%; }
	  .pull-md-12 {
		left: 100%; }
	  .push-md-0 {
		right: auto; }
	  .push-md-1 {
		right: 8.33333%; }
	  .push-md-2 {
		right: 16.66667%; }
	  .push-md-3 {
		right: 25%; }
	  .push-md-4 {
		right: 33.33333%; }
	  .push-md-5 {
		right: 41.66667%; }
	  .push-md-6 {
		right: 50%; }
	  .push-md-7 {
		right: 58.33333%; }
	  .push-md-8 {
		right: 66.66667%; }
	  .push-md-9 {
		right: 75%; }
	  .push-md-10 {
		right: 83.33333%; }
	  .push-md-11 {
		right: 91.66667%; }
	  .push-md-12 {
		right: 100%; }
	  .offset-md-0 {
		margin-right: 0%; }
	  .offset-md-1 {
		margin-right: 8.33333%; }
	  .offset-md-2 {
		margin-right: 16.66667%; }
	  .offset-md-3 {
		margin-right: 25%; }
	  .offset-md-4 {
		margin-right: 33.33333%; }
	  .offset-md-5 {
		margin-right: 41.66667%; }
	  .offset-md-6 {
		margin-right: 50%; }
	  .offset-md-7 {
		margin-right: 58.33333%; }
	  .offset-md-8 {
		margin-right: 66.66667%; }
	  .offset-md-9 {
		margin-right: 75%; }
	  .offset-md-10 {
		margin-right: 83.33333%; }
	  .offset-md-11 {
		margin-right: 91.66667%; }
	}
	@media (min-width: 992px) {
	 .col-lg-1 {
		float: right;
		width: 8.33333%; }
	  .col-lg-2 {
		float: right;
		width: 16.66667%; }
	  .col-lg-3 {
		float: right;
		width: 25%; }
	  .col-lg-4 {
		float: right;
		width: 33.33333%; }
	  .col-lg-5 {
		float: right;
		width: 41.66667%; }
	  .col-lg-6 {
		float: right;
		width: 50%; }
	  .col-lg-7 {
		float: right;
		width: 58.33333%; }
	  .col-lg-8 {
		float: right;
		width: 66.66667%; }
	  .col-lg-9 {
		float: right;
		width: 75%; }
	  .col-lg-10 {
		float: right;
		width: 83.33333%; }
	  .col-lg-11 {
		float: right;
		width: 91.66667%; }
	  .col-lg-12 {
		float: right;
		width: 100%; }
	 .pull-lg-0 {
		left: auto; }
	  .pull-lg-1 {
		left: 8.33333%; }
	  .pull-lg-2 {
		left: 16.66667%; }
	  .pull-lg-3 {
		left: 25%; }
	  .pull-lg-4 {
		left: 33.33333%; }
	  .pull-lg-5 {
		left: 41.66667%; }
	  .pull-lg-6 {
		left: 50%; }
	  .pull-lg-7 {
		left: 58.33333%; }
	  .pull-lg-8 {
		left: 66.66667%; }
	  .pull-lg-9 {
		left: 75%; }
	  .pull-lg-10 {
		left: 83.33333%; }
	  .pull-lg-11 {
		left: 91.66667%; }
	  .pull-lg-12 {
		left: 100%; }
		.push-lg-0 {
		right: auto; }
	  .push-lg-1 {
		right: 8.33333%; }
	  .push-lg-2 {
		right: 16.66667%; }
	  .push-lg-3 {
		right: 25%; }
	  .push-lg-4 {
		right: 33.33333%; }
	  .push-lg-5 {
		right: 41.66667%; }
	  .push-lg-6 {
		right: 50%; }
	  .push-lg-7 {
		right: 58.33333%; }
	  .push-lg-8 {
		right: 66.66667%; }
	  .push-lg-9 {
		right: 75%; }
	  .push-lg-10 {
		right: 83.33333%; }
	  .push-lg-11 {
		right: 91.66667%; }
	  .push-lg-12 {
		right: 100%; }
	  .offset-lg-0 {
		margin-right: 0%; }
	  .offset-lg-1 {
		margin-right: 8.33333%; }
	  .offset-lg-2 {
		margin-right: 16.66667%; }
	  .offset-lg-3 {
		margin-right: 25%; }
	  .offset-lg-4 {
		margin-right: 33.33333%; }
	  .offset-lg-5 {
		margin-right: 41.66667%; }
	  .offset-lg-6 {
		margin-right: 50%; }
	  .offset-lg-7 {
		margin-right: 58.33333%; }
	  .offset-lg-8 {
		margin-right: 66.66667%; }
	  .offset-lg-9 {
		margin-right: 75%; }
	  .offset-lg-10 {
		margin-right: 83.33333%; }
	  .offset-lg-11 {
		margin-right: 91.66667%; }
	}
	@media (min-width: 1200px) {
	  .col-xl-1 {
		float: right;
		width: 8.33333%; }
	  .col-xl-2 {
		float: right;
		width: 16.66667%; }
	  .col-xl-3 {
		float: right;
		width: 25%; }
	  .col-xl-4 {
		float: right;
		width: 33.33333%; }
	  .col-xl-5 {
		float: right;
		width: 41.66667%; }
	  .col-xl-6 {
		float: right;
		width: 50%; }
	  .col-xl-7 {
		float: right;
		width: 58.33333%; }
	  .col-xl-8 {
		float: right;
		width: 66.66667%; }
	  .col-xl-9 {
		float: right;
		width: 75%; }
	  .col-xl-10 {
		float: right;
		width: 83.33333%; }
	  .col-xl-11 {
		float: right;
		width: 91.66667%; }
	  .col-xl-12 {
		float: right;
		width: 100%; }
	  .pull-xl-0 {
		left: auto; }
	  .pull-xl-1 {
		left: 8.33333%; }
	  .pull-xl-2 {
		left: 16.66667%; }
	  .pull-xl-3 {
		left: 25%; }
	  .pull-xl-4 {
		left: 33.33333%; }
	  .pull-xl-5 {
		left: 41.66667%; }
	  .pull-xl-6 {
		left: 50%; }
	  .pull-xl-7 {
		left: 58.33333%; }
	  .pull-xl-8 {
		left: 66.66667%; }
	  .pull-xl-9 {
		left: 75%; }
	  .pull-xl-10 {
		left: 83.33333%; }
	  .pull-xl-11 {
		left: 91.66667%; }
	  .pull-xl-12 {
		left: 100%; }
	  .push-xl-0 {
		right: auto; }
	  .push-xl-1 {
		right: 8.33333%; }
	  .push-xl-2 {
		right: 16.66667%; }
	  .push-xl-3 {
		right: 25%; }
	  .push-xl-4 {
		right: 33.33333%; }
	  .push-xl-5 {
		right: 41.66667%; }
	  .push-xl-6 {
		right: 50%; }
	  .push-xl-7 {
		right: 58.33333%; }
	  .push-xl-8 {
		right: 66.66667%; }
	  .push-xl-9 {
		right: 75%; }
	  .push-xl-10 {
		right: 83.33333%; }
	  .push-xl-11 {
		right: 91.66667%; }
	  .push-xl-12 {
		right: 100%; }
	  .offset-xl-0 {
		margin-right: 0%; }
	  .offset-xl-1 {
		margin-right: 8.33333%; }
	  .offset-xl-2 {
		margin-right: 16.66667%; }
	  .offset-xl-3 {
		margin-right: 25%; }
	  .offset-xl-4 {
		margin-right: 33.33333%; }
	  .offset-xl-5 {
		margin-right: 41.66667%; }
	  .offset-xl-6 {
		margin-right: 50%; }
	  .offset-xl-7 {
		margin-right: 58.33333%; }
	  .offset-xl-8 {
		margin-right: 66.66667%; }
	  .offset-xl-9 {
		margin-right: 75%; }
	  .offset-xl-10 {
		margin-right: 83.33333%; }
	  .offset-xl-11 {
		margin-right: 91.66667%; } 
	}

	[class*="offset"] {
		margin-left: 0;
	}
	[class*="push"] {
		left:auto;
	}
	[class*="pull"] {
	   right:auto;
	}

	/* edtit theme */

	.bx-viewport {
	  direction: ltr !important;}

	.lang-rtl * .owl-carousel {
		direction: ltr !important;
	}
	.owl-carousel .owl-wrapper-outer {
		direction: ltr !important;
	}
	.back-top {
		left: 25px;
		right:auto;
	}
	#header  nav.header-nav {
		right: auto;
		left:0;
	}
	@media(min-width: 992px){
		.sibar_left {
			.open-sidebar {
				left:auto;
				right:15px;
			}
			.close-sidebar {
				left:45px;
				right:auto;
			}
		}
	}
	@media(min-width: 1200px){
		.sibar_left .open-sidebar {
			right: 80px;
			left:auto;
		}
	}
	/* item product */
	
	.js-product-miniature{
		.add-to-links  {
			left:15px;
			right:auto;
			@media (max-width:991px){
				left:30px;
			}
		}
		
		.product_desc {
			.variant-links {
				text-align: right;
			}
			
		}
	}
	.js-product-miniature .product-price-and-shipping {
		left:11px;
		right:auto;
		
	}
	.new,
	.on-sale {
		left: 10px;
		right: auto;
		@media  (max-width:1199px) { 
			left:auto;
			right:10px;	
		}
		&:after {
			content: "";
			border: 6px solid transparent;
			border-left: 6px solid #c4df9a;
			right:-11px;
			left: auto;
		}
		&:before {
			float:right;
			margin: 0 -8px 0 8px;
		}
	}
	.on-sale {
		&:after {
			border-left: 6px solid $main;
		}
		
	}
	/* end item product */
	/* module slideshow */
	
	.banner7-des {
		.info ,a,.container {
			direction:ltr !important;
		}
	}
	/* end module slideshow */
	/* module listcategoris */

	.poslistcategories  { 
		.list-categories {
			direction:rtl;
			.content-listcategoreis{
				.name_categories a {
					&:before {		
						left:auto;
						right:0;
					}
				}
				a.links_cate {
					&:after {
						background: url(../img/bg_links_rtl.png);
					}
					&:hover:after {
						-webkit-animation: passing-reverse 1200ms linear infinite;
						-moz-animation: passing-reverse 1200ms linear infinite;
						-ms-animation: passing-reverse 1200ms linear infinite;
						-o-animation: passing-reverse 1200ms linear infinite;
						animation: passing-reverse 1200ms linear infinite;
					}
				
				}
			}		
		}
	}
	/* end module listcategoris */

	/* module megamenu */
	.pt_custommenu {
		.pt_menu {
			.parentMenu a {
				i {
					float: left;
					padding: 0 7px 0 0;
				
				}
			}
			&:first-child  .parentMenu a{
				padding-right:0;
				padding-left:20px;
				@media (max-width:1199px){
					padding-left:10px;
				}
			}
			.popup {
				.block1 {
					float: right;
					.column {
						float: right;
						.itemSubMenu {
							.itemMenuName {
								&:before {
									margin:0 0 0 10px;
								}
							}
						}
					}
				}
			}
		}
	}

	.pt_custommenu_mobile {
		text-align: right;
		.navbar {
			.navbar-inner .btn-navbar {
				&:after {
					left: 0;
					right: auto;
				}
			}
		}
	}

	ul#pt_custommenu_itemmobile {
		li {
			.grower {
				left: 0;
				right: auto;	
			}
		
		}
	}
	/* end module megamenu */

	/* module bestseller */
	.pos-bestsellers-product {
		.js-product-miniature {
			.img_block {
				float:right;
				margin:0 0 0 20px;
				@media (min-width: 768px) and (max-width: 991px) {
					margin:0;
					margin-bottom:20px;
		
				}
				
			}
	
		}
		.owl-buttons  > div {
			&.owl-prev {
				left:0;
				right:auto;		
			}
			&.owl-next{
				left:30px;
				right:auto;
			}
			
		} 
	}
	/* end module bestseller */
	/* module contact */

	.contact-link {
		float: left;
		.info_box {
			
			.icon-phone {
				float: left;
			}
			.icon-fax {
				float: left;
				
			}
			.icon-email {
				float: left;
				
			}
			.dropdown-menu {
				margin-left:15px;
				margin-right: 0;
				right: auto;
				left:100%;
				&:before,&:after {
					border: 7px solid transparent;
					border-right: 7px solid white;
					left: -13px;
					right:auto;
				}
				&:after  {
					border-right: 7px solid rgba(0,0,0,0.1);
					left: -14px; 
				}
			}
		}
	}
		
	/* end module contact */

	/* module social */
	#header {
		.social_follow{
			float:left;
			li {
				&:last-child {
					padding-right:0;
					padding-left:10px;
				}
			}
		}
	}
	
	/* end module social */

	/* module search block */
	.block-search  {
		float:left;
		@media (max-width: 991px) {
			float:none;
		}
		.search-icon {
			&:after {
				left: 45px;
				right:auto;
			}
		}
	}
	.search_top {
		form {
			.text_input {
				padding: 10px 10px 10px 70px;
			}
			button {
				left: 0;
				right: auto;
			}
		}
	}

	@media (max-width: 767px) {
		.block-search  {
			float:none;
			
			
		}
	}
	.ui-corner-all {
		li a {
			img {
				float:right;
				margin:0 0 0 30px;
			}
			
		}
	}
	
	/* end module search block */

	/* setting */
	.setting_top  {
		float: left;
		.content-setting {
			left: 100%;
			right:auto;
			margin-right:0;
			margin-left: 15px;
			text-align:right;
			&:before,&:after {
				border: 7px solid transparent;
				border-right: 7px solid white;
				left: -13px;
				right: auto;
			}
			&:after  {
				border-right: 7px solid rgba(0,0,0,0.1);
				left: -14px;
			}
		}
		
	}
	.localiz_block {
		float: right;
		@media (max-width: 991px) {
			float:none;
		}
		
		.dropdown-menu {
			right: 0;
			left:auto;
			z-index: 2;
		}
	}
	
	#user_info_top.localiz_block {
		float: left;
		@media (max-width: 991px) {
			float:none;
		}
	}
	/* end setting */
	/* welcome */
	.poswelcome {
		left:auto;
		right:0;
	}
	/* end welcome */
	/* module productcomments */
	//addons comments products
	#sendComment p.submit {
		text-align: left;
	}

	#sendComment p.closeform {
		float: left;
	}

	.star {
		float: right;
	}
	// module productcomments

	.cancel, .star {
		float: right;
	}
	#product_comments_block_extra {
		.comments_note span,
		.star_content {
			float: right;
		}
	}

	.comments_advices {
		a {
			margin-left: 8px;
			margin-right:0;
			&:before{
				margin:0 0 0 6px;
			}
			&.reviews{
				margin-left: 9px;
				direction: ltr !important;
				@media (min-width: 768px) and (max-width: 991px) { // min 768px max 991px
					margin-left: 0;
				}
			}

		}
	}


	/* pop-in add grade/advice ********************************************************************* */
	#new_comment_form {
		text-align: right;
		ul.grade_content {
			.cancel {
				margin-left: 5px;
				margin-right:0;
			}
		}
	
		.submit {
			text-align: left;
		}

		#criterions_list {
			label {
				float: left;
				margin: 0 0 0 10px;
			}
			.star_content {
				float: right;
			}
		}
	}
	/* TAB COMMENTS ******************************************************************************** */
	#product_comments_block_tab {

		div.comment {
			.comment_author {
				.star_content {
					margin: 0 5px 0 0;
				}
			}
		}
	}
	.fl {
		float: right;
	}
	.fr {
		float: left;
	}
	#products .comments_note, .featured-products .comments_note, .product-accessories .comments_note {
		text-align: right;
	}
	/* end module productcomments */
	/* module cart block */

	.blockcart {
		float: left;
		margin: 0 0 0 18px;
		@media (max-width: 991px) {
		float:none;
	}
		.header {
			a {
				.item_total {
					right: 30px;
					left:auto;
					&:before {
						border: 4px solid transparent;
						border-left: 5px solid $main;
						right: -8px;
						left:auto;
					}
				}
			}
		}
		.body {
			right: auto;
			left: 0;
			text-align:right;
			ul li {
				.img_content {
					float: right;
					margin: 0 0 0 20px;
					.product-quantity {
						right: 5px;
						left: auto;
					}
				}
				.right_block {
					padding: 0 0 0 15px;
					.remove-from-cart {
						left: 0;
						right: auto;
					}
				}
			}
			.price_content {
				.price_inline {
					.label {
						float: right;
					}
					.value {
						float: left;
					}
				}
			}
		}
	}
		/* style layer cart */
	
		#blockcart-modal .divide-right {
			border:0;
			border-left:$main_border;
		
		}
		#blockcart-modal button.close {
			float:left
		}
		#blockcart-modal .cart-content {
			padding-right: 2.5rem;
			padding-left:0;
		}
		/* end style layer cart */
		
		
	/* end module cart block */

	/* module tabcateslider */
	.tab-category-container-slider {
		.tab_cates {
			li {
				border-left:0;
				border-right:1px solid #e1e1e1;
				&:first-child {
					border-right:0;
				}		
			}

		}
		
	}
	/* end module tabcateslider */

	/* module newletter */

	.ft_newsletter {	
		.container {
			//background: url(../img/bg_newletter_rtl.jpg) no-repeat center;
			@media (max-width: 767px) {
				background: #f2f2f2;
			}
		}
		form{
			.input_txt{
				padding: 10px 10px 10px 140px;
			}
			.btn{
				right:auto;
				left:0;
			}
			
		}
	}

	/* end module newletter */

	/*intagram_home  */
	#instagram_block {
		.our_instagram {
			a {
				&:after {
					content: "\f100";
					float:left;
				}
			}
		}
	}
	#instagram_block_home {
		.title_instagram {
			.des_intagram {
				a {
					float:left;
					@media (max-width:479px) {
						float:right;
					}
					&:after {
						content: "\f100";
					}
				}
			}
		}
	}
	/*end intagram _home  */

	/* static block */
	
	.banner-box {
		.links a,.banner_content a{
			&:after {
				-moz-transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
	.banner-slide  {
		.banner-box:last-child {
			text-align:left;
		}
	}
	/* end static block */
	/* footer */
	#footer{
		.footer_block{
			h3{
				@media (max-width: 767px) {
					&:after {
						left:20px;
						right:auto;
					}
				}
			}
			.footer-about-us {
				.add {
					padding-right:30px;
					padding-left:0;
					&:before {
						right:0;
						left:auto;
					}
				}
			}
			.footer_list  {
				li {
					a {
						-moz-transform: translateX(0);
						-webkit-transform: translateX(0);
						-o-transform: translateX(0);
						-ms-transform: translateX(0);
						transform: translateX(0);
						&:before{
							margin:0 0 0 10px;
							-moz-transform: translateX(0);
							-webkit-transform: translateX(0);
							-o-transform: translateX(0);
							-ms-transform: translateX(0);
							transform: translateX(0);
						}
						&:hover {
							-moz-transform: translateX(-5px);
							-webkit-transform: translateX(-5px);
							-o-transform: translateX(-5px);
							-ms-transform: translateX(-5px);
							transform: translateX(-5px);
							&:before {
								-moz-transform: translateX(-5px);
								-webkit-transform: translateX(-5px);
								-o-transform: translateX(-5px);
								-ms-transform: translateX(-5px);
								transform: translateX(-5px);
							}
						}
					
					}
				}
			}
			
		}	
		.footer-bottom { 
			address {
				@media (max-width: 767px) {
					text-align:right;
				}
			}
			.payment {
				text-align: left;
				@media (max-width: 767px) {
					text-align:right;
				}
			}
		}
	}
	/* end footer */
	/* category page */
	#js-product-list-top {
		ul.display  {
			float:right;
			margin:0 0 0 50px;
			@media (min-width: 768px) and (max-width: 991px) {
				margin-left:20px;
			}
			li {
				float:right;
				margin:0 0 0 5px;
			}
		}
	}
	#js-product-list {
		.product_content {
			&.list {
				.js-product-miniature {
					.product_desc  {
						padding:0 30px 0 0;
					
					}
				}
			}
			
		}
	}
	@media (min-width:480px) and (max-width:543px) {
		#js-product-list .product_content .item-product.grid  {
			float:right;
			width:50%;
		}
	}
	
	#search_filters .facet {
		.custom-checkbox input[type="checkbox"] + span{
			margin: -3px 3px 0 3px;
		}
		.facet-label a {
			direction: ltr !important;
		}
	}
	@media (max-width: 767px) {
		#category #left-column #search_filters .facet .navbar-toggler {
			padding: 0.625rem 0 0 3rem;
		}
	}
	.products-selection{
		p, .sort-by{
			float:right;
		}
		
		.products-sort-order .dropdown-menu{
			left: 15px;
			right: auto;
		}
	}
	.block-categories .collapse-icons {
		left: 0;
		right: auto;
	}
	/* end category page */

	/* product page */


	.product-quantity .qty {
		margin:0 0 0 20px;
	}
	.product-quantity .input-group-btn-vertical {
		float: right;
		display: block;
	}

	.tabs {
		.nav-tabs {
			float: right;
			@media (max-width:767px) {
				float:none;
			}
			.nav-item {
				.nav-link {
					&:before {
						right:auto;
						left:-2px;
					}
				}
			}		
		}
	}
	.tab-content {
		border:0;
		border-right: 2px solid #ebebeb;
		@media (max-width: 767px) {
			border: 2px solid #ebebeb;
		}
	}
	
	/* end product page */

	.blog_post .slick-prev, .blog_post .slick-next {
		line-height: 45px;
	}


	/* responsive */
	@media (max-width:767px) {
		.blockcart {
			float:none;
			.body {
				right:auto;
				left:50%;
				-moz-transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%)
				}
		}

	}


	/* end responsive */

	.slick-slider .slick-track, .slick-slider .slick-list {
		direction: ltr !important;
	}
	.blog_post .slick-prev, .blog_post .slick-next {
		direction: ltr !important;
	}
	.close {
		float: left;
	}
}
/* end rtl theme */


.bestsellerSlide{
	.owl-buttons{
		> div{
			i{ display: none; }
		}
	}
}

body.page-product{
	#main{
		> .row{
			.col-xs-12{
				@include respond-to(tablette){
					width: 100%;
				}
			}
		}
	}
}