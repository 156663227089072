@use "sass:math";

$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome";

@import "bourbon";
@import "partials/_variables";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min";
@import "partials/_mixins";
@import "partials/regles";
@import "partials/_fonts";
@import "partials/_commons";
@import "partials/_header";
@import "components/drop-down";
@import "components/search-widget";
@import "components/menus";
@import "components/checkout";
@import "components/customer";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/home";
@import "custom/custom";

$material-icons-font-path: '~material-icons/iconfont/';
@import 'material-design-icons/iconfont/material-icons.css';



.popover {
  font-family: inherit;
}

.container{
  max-width: 90%;
  margin: 0 auto;
}
/*** WRAPPER ***/
#wrapper {

  .banner {
    margin-bottom: 1.5rem;
    display: block;
    img {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .breadcrumb {
    background: transparent;
    padding: 0;
    &[data-depth="1"] {
      display: none;
    }
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      display: inline;
      &::after {
        content: "/";
        color: $gray-dark;
        margin: em(5px);
      }
      &:last-child {
        content: "/";
        color: $gray-dark;
        margin: 0.313rem;
        &::after {
          content: "";
        }
      }
      a {
        color: $gray-darker;
      }
    }
  }
}
/*** MAIN ***/
#main {
  .page-header {
    margin-bottom: 1.563rem;
  }
  .page-content {
    margin-bottom: 1.563rem;
    h6 {
      margin-bottom: 1.125rem;
    }
    #notifications {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .page-footer {
    margin-bottom: 1.563rem;
  }
}
#notifications {
  ul {
    margin-bottom: 0;
  }
}
/*** FOOTER ***/
#footer {
  padding-top: 2.5rem;
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #wrapper {
    box-shadow: none;
  }
  #checkout-cart-summary {
    float: none;
    width: 100%;
    margin-top: 1rem;
  }
  #header {
    .header-nav {
      .top-logo {
        line-height: 50px;
        vertical-align: middle;
        width: 200px;
        margin: 0 auto;
        a {
          img {
            max-height: 50px;
            max-width: 100%;
          }
        }
      }
      .right-nav {
        flex-direction: column;
      }
      .user-info {
        text-align: left;
        margin-left: 0;
        .logged {
          color: $brand-primary;
        }
      }
      .blockcart {
        margin-left: 0;
        background: inherit;
        &.active {
          margin-left: 0.5rem;
        }
        &.inactive {
          .cart-products-count {
            display: none;
          }
        }
      }
    }
    .header-top {
      padding-bottom: 0;
      a[data-depth="0"] {
        color: $gray-darker;
      }
    }
  }
  section.checkout-step {
    width: 100%;
  }
  .default-input {
    min-width: 100%;
  }
  label {
    clear: both;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .block-contact {
    padding-left: 0.9375rem;
    border: none;
  }
  .menu,
  .dropdown-item {
    padding-left: 0;
  }
}
@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
  #header .logo {
    width: auto;
  }
  #products .product-miniature,
  .featured-products .product-miniature {
    margin: 0 auto;
  }
  .sub-menu {
    left: 0;
    min-width: 100%;
  }
  #blockcart-modal .product-image {
    width: 100%;
    display: block;
    max-width: 250px;
    margin: 0 auto 0.938rem;
  }
  #blockcart-modal .cart-content {
    padding-left: 0;
  }
  #blockcart-modal .product-name,
  #product-availability {
    margin-top: $small-space;
  }
  #search_filters .facet .facet-label {
    text-align: left;
  }
  .block-category .category-cover {
    position: relative;
    text-align: center;
  }
  .block-category {
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(xs) {
  #left-column, #content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
