.lang-rtl {
  * {
    direction: rtl !important;
  }
  main {
    text-align: right !important;
  }
}
body,
html {
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray-darker;
  line-height: 1.25em;
}

#wrapper{
	margin: 0;
	background:transparent;
	box-shadow: none;
	padding: 0;
  
	#main .page-footer {
		margin:0;
	}
}

ul {
  list-style: none;
  padding-left: 0;
}
a:hover {
  color: $brand-primary;
  text-decoration: none;
}
p {
  font-size: 0.938rem;
  color: $gray-dark;
  font-weight: 400;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}
.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin: 0.313rem;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0,0,0,0.05);
  cursor: pointer;
  background-size: contain;
  &.active,
  &:hover {
    border: 2px solid $gray-darker;
  }
}
.facet-label {
  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 2px solid $gray-darker;
    }
  }
}
.h1,
.h2,
.h3 {
  text-transform: uppercase;
  color: $gray-darker;
}
.h4 {
  font-weight: $headings-font-weight;
  color: $gray-darker;
}
.btn-primary,
.btn-secondary {
  @include box-shadow;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  .material-icons {
    margin-right: $small-space;
  }
}
.btn-tertiary {
  @extend .btn-secondary;
  text-transform: lowercase;
  color: $gray;
  box-shadow: 0.063rem 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.25rem;
  margin: 0.25rem 0;
  font-weight: 400;
  font-size: $font-size-sm;
  .material-icons {
    font-size: $base-font-size;
  }
}
.card {
  @include box-shadow;
}
label ,.label {
  color: $gray-darker;
  text-align: right;
  font-size: 0.875rem;
}
small.label, small.value {
  font-size: 0.813rem;
}
.form-control-label {
  padding-top: 0.625rem;
}
.form-control {
  background: $gray-light;
  color: $gray;
  border: none;
  padding: 0.5rem 1rem;
  &:focus {
    background-color: white;
    outline: 0.188rem solid $brand-primary;
  }
}
.input-group {
  &.focus {
    outline: 0.188rem solid $brand-primary;
  }
  .form-control:focus {
    outline: none;
  }
  .input-group-btn > .btn {
    border: 0;
    box-shadow: none;
    color: #ffffff;
    font-size: 0.688rem;
    font-weight: normal;
    margin-left: 0;
    padding: 0.656rem 1rem;
    text-transform: uppercase;
  }
}
.form-control-select {
  height: 2.625rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $gray-light url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
  padding-right: 2rem;
}
.form-control-valign {
  padding-top: 0.5rem;
}
.form-control-comment {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  color: $gray-dark;
}
.form-control-submit {
  &.disabled {
    background: $brand-primary;
    color: white;
  }
}
.form-group {
  &.has-error {
    input,
    select {
      outline: 0.188rem solid $brand-danger;
    }
    .help-block {
      color: $brand-danger;
    }
  }
}
.group-span-filestyle {
  label {
    margin: 0;
  }
  .btn-default {
    background: $brand-primary;
    color: white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
}

.custom-radio {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: $gray 2px solid;
  background: white;
  margin-right: 1.25rem;
  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
  }
  input[type="radio"]:checked + span {
    display: block;
    background-color: $brand-primary;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
  }
}
.custom-checkbox {
  position: relative;
  input[type="checkbox"] {
    margin-top: 0.25rem;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    + span {
      margin-right: 3px;
      display: inline-block;
      width: 0.938rem;
      height: 0.938rem;
      vertical-align: middle;
      cursor: pointer;
      border: 2px $gray-darker solid;
      .checkbox-checked {
        display: none;
        margin: -0.25rem 1.438rem;
        font-size: 1.1rem;
        color: $gray-darker;
      }
    }
    &:checked + span {
      .checkbox-checked {
        display: block;
      }
    }
  }
  label {
    text-align: left;
  }
}
.text-muted {
  font-size: 0.875rem;
}
.done {
  color: $brand-success;
  display: inline-block;
  padding: 0 0.813rem;
  margin-right: 1.563rem;
}
.thumb-mask {
  > .mask {
    position: relative;
    width: 3.438rem;
    height: 3.438rem;
    overflow: hidden;
    border: $gray-light 1px solid;
    margin: $small-space 0;
    img {
      width: 55px;
      height: 55px;
    }
  }
}
.definition-list {
  dl {
     display: flex;
    flex-wrap: wrap;
    dt {
      font-weight: normal;
    }
    dd,
    dt {
      flex: 0 0 45%;
      background: $gray-light;
      padding: 0.625rem;
      margin: 0.125rem;
      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }
  }
}
.help-block {
  margin-top: $small-space;
}
.btn.disabled,
.btn.disabled:hover {
  background: $gray;
}
.alert-warning {
  .material-icons {
    color: $warning;
    font-size: 2rem;
    margin-right: $small-space;
    padding-top: $extra-small-space;
  }
  .alert-text {
    font-size: 0.938rem;
    padding-top: $small-space;
  }
  .alert-link {
    border-radius: 2px;
    border-width: 2px;
    margin-left: $small-space;
    padding: $extra-small-space $medium-space;
    font-weight: 600;
    font-size: 0.813rem;
    color: $btn-tertiary-color;
  }
  ul li:last-child .alert-link {
    color: white;
  }
  .warning-buttons {
    margin-top: $extra-small-space;
  }
}
.btn-warning {
  @include transition(all .4s ease-in-out);
}
.btn-tertiary-outline {
  color: $btn-tertiary-color;
  background-image: none;
  background-color: transparent;
  border-color: $btn-tertiary-color;
  border: 0.15rem solid $btn-tertiary-color;
  @include transition(all .4s ease-in-out);
  &:hover {
    border-color: $btn-tertiary-hover;
    color: $btn-tertiary-hover;
  }
}
.alert {
  font-size: 0.813rem;
}
.nav-item {
  .nav-link,
  .nav-separtor {
    color: $gray;
    font-weight: bold;
    &.active {
      color: $gray-darker;
    }
  }
}


//MEDIAS

.back{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  &.contain{
    background-size: contain;
  }
}


/*////////////////////////////////
 /////// FORMAT DES IMAGES ///////
 ///////////////////////////////*/

/* RATIOS POUR IMAGE */
*[class *= "ratio--"]{
	@include aspect-ratio(3,2);
}
.ratio--nbor{&:before{padding-top:61.803398875%;}}
.ratio--portrait{&:before{padding-top:getRatio(2, 3);}}
.ratio--carre{&:before{padding-top:getRatio(1, 1);}}
.ratio--ann{&:before{padding-top:82%;}}
.ratio--pano{&:before{padding-top:getRatio(10, 6);}}
.ratio--hyperpano{
    &:before{ padding-top:getRatio(3, 1); }
        
    @include respond-to(tablette){ &:before{padding-top:getRatio(3, 2); }}
    @include respond-to(android){ &:before{padding-top:getRatio(1, 1); }}
}

.ratio--slideshow{
	&:before{padding-top:getRatio(3, 2);}

	@include respond-to(tablette){
		&:before{padding-top:getRatio(1, 1);}
	}
}


.back{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.imgContain{
    background-size: contain !important;
}


/*/////////////////////////////////
 /////// SYSTEME D'ANNONCES ///////
 ////////////////////////////////*/


*[class *= "grille-annonce"]{
    @include display;
    @include grid($flex-wrap: wrap);
    margin: 0 0 $gap*2 0;

    @include respond-to(android){
        margin-bottom: $gap;
    }
}

.grille-annonce--l2{
    .ann{@include annonce--l2(30px);}
}

.grille-annonce--l3{
    .ann{@include annonce--l3(30px);}
}

.grille-annonce--l4{
    .ann{@include annonce--l4(30px);}
}

.grille-annonce--l5{
    .ann{@include annonce--l5(30px);}
}

.grille-annonce--l6{
    .ann{@include annonce--l6(30px);}
}

.grille-annonce--lu{
    .ann{@include annonce--l4(30px);}
}

.grille-annonce--quinconce{
    .ann:nth-child(even){
        @include respond-from(tablette){
            .ann-elem{
                &:nth-child(1){order:2;}
                &:nth-child(2){order:1;}
            }
            .ann-img-panneau{
                left:auto; right: -11px;
            }
        }
    }
}

.grille-annonce--gallerie{
    .ann{.ann-elem{ width: 100% !important; }}
}

.ann{
    @include display($z-index:1);
    @include grid($flex-wrap:wrap);
    align-items: center;
    margin-bottom:$gap*2;
    background-color: white;
    color:$col-txt;    
    @include trans;

    &.ann-gallerie, &.ann-gallerie--survol, [class *= "grille-annonce--l"] &{
        body.ie &{
            float:left;
            clear:none;
        }
    }

    &.ann-gallerie--survol{
        overflow:hidden;
    }
    
    &:hover{
        transform: scale(1.05);
        z-index:17; // pour passer sur les autres annonces au cas où
    }

    @include respond-to(android){
        margin-bottom: $gap;
    }
}

.ann-elem{
    @include display;    
    width: 50%;

    //repartition gauche / droite
    &:first-child{
        width:45%;
    }
    &:nth-child(2){
        width: 55%;
        padding: $gap;
    }
    //comportement si pas d'images
    &:only-child{
        width:100%;
    }
    
    &:last-child{
        body.ie &{
            clear:right;
        }
    }

    // specificite lien utile
    .ann-gallerie &, .grille-annonce--gallerie &{
        display: block;
        position:relative;
        width: 100%;

        &:nth-child(2){
            padding: $gap;
        }
    }
    
    body.ie &{
        float:left;
    }

    @include respond-to(600px){
        width: 100% !important;
    }

}

//image
.ann-img{
    cursor:pointer;
    @include trans;
    overflow: hidden;

    >img{@include display; width:100%;}
    >a{@include display; height:100%;}
    .back{ @include trans; }

    .ann:hover &{
        @include trans;

        .back{ transform:scale(1.4); @include trans; }
    }
}

//contenu
.ann-cnt{
    @include display;
    @include trans;

    .ann-gallerie--survol &{
        position:absolute;
        z-index:2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width:100%; height: 100%;
        left:0; top: 0;
        background-color: rgba($secondary, 0.8);
        padding-bottom: 0px;
        overflow:hidden;
        padding: $gap;
    }
    .ann-gallerie--survol:hover &{
        @include trans;
    }
}

/*style annonce*/
//titre
.ann-titre{
    @include display;

    h2, h4{
        padding:0 !important; margin:0 !important; text-align:left;
        font-size: 18px;
        font-weight: 200;
        font-family:$typo-title;
        text-transform: uppercase;
        @include trans;

        *[class*=".grille-annonce--"] &, .ann-gallerie &{
            font-size:14px;
        }

        .ann-gallerie--survol &{
            display: flex;
            color:$white;
            text-transform: uppercase;

            a{
                color:$white;
                padding: $gap 0;
            }
        }

        a{
            @include display;
            color: $black;
            padding: 0 0 $gap 0;
            font-weight: 200;
            @include trans;

            &:hover{
                color: $secondary;
                @include trans;
            }

            @include respond-to(android){
                padding: $gap*0.5 0;
            }
        }
    }
}

.ann-detail{
    @include display;
    background:rgba($black, 0.1);
    font-weight:bold;
    margin: 0 0 2px 0;

    >span{
        padding: 2px 5px;
  }
}

.ann-extrait{
    margin-top: $gap*0.5;
}

.ann-desc.txt_contenu{
    $font-size: 15px;
    $line-height: 1.8;
    $lines-to-show: 3;

    font-size:$font-size;
    height: ($font-size*$line-height*$lines-to-show) - 2px;
    line-height: $line-height;
    font-weight: 300;

    @include display;
    overflow:hidden;
    margin: 3px 0 5px 0;

    .ann-gallerie--survol &{
        display: flex;
        color:$white;

        a{ color:$white;}
    }

    h3, h4{
        font-family: $typo;
        text-align: left;
        font-size: 13px;
    }

    @include respond-to(android){
        font-size: 12px;
        height: (12px*$line-height*$lines-to-show) - 2px;
    }
}

.ann-bts{
    margin-top: $gap;
    opacity: 0;
    visibility: hidden;
    @include trans;

    .bt{
        font-size: 12px;
        color: $main !important;
        background-color: transparent !important;
        border: none;
        padding: 0; margin: 0;
        text-transform: uppercase;
        font-weight: 400;
        text-align: left;
        @include trans;

        &:hover{
            margin-left: $gap;
            @include trans;
        }
    }

    .ann-gallerie--survol &{
        position:absolute;
        left:0px; bottom:0px;
        width:100%;
        opacity:0;
        padding: 5px;        
        transform: translateY(50px);
        @include trans;
        background-color: rgba($black,.1);

        .bt{
            color: $white !important;
            border-color: $white;

            &:hover{
                opacity: 0.5;
            }
        }
    }
    .ann-gallerie--survol:hover &{
        opacity:1;
        transform: translateY(0px);
        @include trans;
    }

    .ann:hover &{
        opacity: 1;
        visibility: visible;
        @include trans;
    }
}