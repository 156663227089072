.carousel {
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 1.5rem;
  .direction {
    z-index: auto;
  }
  .carousel-inner {
    height: 340px;
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      margin-left: 0;
    }
    .caption {
      position: absolute;
      bottom: 28px;
      left: 90px;
      color: white;
      max-width: 340px;
      .caption-description p {
        color: white;
      }
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      &::before {
        content: "";
      }
      i {
        font-size: 3.125rem;
        color: white;
      }
      &:hover {
        i {
          color: $brand-primary;
        }
      }
    }
    .icon-prev {
      left: 1rem;
    }
    .icon-next {
      right: 2rem;
    }
    &.left,
    &.right {
      background: none;
    }
  }
}
