$gray-darker: #414141;
$gray-dark: #878787;
$gray: #acaaa6;
$gray-light: #ebebeb;
$gray-lighter: #f6f6f6;
$brand-primary: #2fb5d2;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.313rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.938rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.813rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.938rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$OpenSansPath: "~open-sans-fontface/fonts";
$OpenSansVersion: "1.1.0";
$input-btn-border-width: 2px;
$input-border-color: $gray-darker;

$fa-font-path: "../fonts/scss";

$main: #ffa800; 
$dark: #333333;
$light: #ffffff;
$font-icon:  "FontAwesome";
$main_border:1px solid #ebebeb;

$gap: 18px;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Urbanist:wght@200;300;400;500;600;700;800;900&display=swap');

$typo-title: 'Urbanist', sans-serif;
$typo: 'Roboto', sans-serif;

$col-txt: #000000; 
$gap-ie-fallback: 2%;