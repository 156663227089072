@import "node_modules/open-sans-fontface/sass/mixins";
@import "node_modules/open-sans-fontface/sass/Regular";
@import "node_modules/open-sans-fontface/sass/Italic";
@import "node_modules/open-sans-fontface/sass/Semibold";
@import "node_modules/open-sans-fontface/sass/SemiboldItalic";
@import "node_modules/open-sans-fontface/sass/Bold";
@import "node_modules/open-sans-fontface/sass/BoldItalic";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
