
.page-index{
    .page-home{

        .section{
            position: relative;

            &.full-section{
                width: 111%;
                margin-left: -5.5%;
                margin-right: -5.5%;
                padding: $gap*2 5%;

                @media screen and (max-width:1000px){
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 18px 56px;
                }

                @media screen and (max-width:600px){
                    padding: 18px;
                }
            }

            h2{
                font-family: $typo-title;
                font-weight: 200;
                font-size: 60px;
                line-height: normal;
                color: black;

                @media screen and (max-width:1000px){
                    font-size: 40px;
                }
            }

            .txt{
                font-family: $typo;
                font-weight: 300;
                font-size: 20px !important;
                line-height: normal;
                margin-top: $gap;
                color: black;

                @media screen and (max-width:1000px){
                    font-size: 16px !important;
                }

                b{
                    color: $main;
                }

                &.medium{
                    max-width: 500px;
                    margin: 0 auto;
                }
            }

            a.bt{
                display:block;
                position: relative;
                margin-top: $gap;
                text-align: center;
                transition: all 75ms ease-in-out;

                span{
                    position: relative;
                    display: inline-block;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 20px;
                    color: black;
                    text-align: center;
                    padding: $gap $gap*2;
                    
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        right: calc(100% - $gap); bottom: 0;
                        width: 76%;
                        height: 2px;
                        background-color: $main;
                        transition: all 75ms ease-in-out 150ms;
                    }

                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0; bottom: 0;
                        width: 2px; height: 0px;
                        background-color: $main;
                        transition: all 50ms ease-in-out 100ms;
                    }
                }

                b{
                    position: absolute;
                    display: block;
                    width: 100%; height: 100%;
                    left:0; top: 0;
                    
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0; top: 0;
                        height: 2px; width: 0px;
                        background-color: $main;
                        transition: all 50ms ease-in-out 50ms;
                    }

                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0; top: 0;
                        height: 0px; width: 2px;
                        background-color: $main;
                        transition: all 50ms ease-in-out;
                    }
                }

                &:hover{
                    transition: all 75ms ease-in-out;

                    span{
                        &:before{
                            right: 0;
                            width: 100%;
                            transition: all 75ms ease-in-out;
                        }

                        &:after{
                            height: 100%;
                            transition: all 50ms ease-in-out 75ms;
                        }

                        
                    }

                    b{
                        &:before{
                            width: 100%;
                            transition: all 50ms ease-in-out 85ms;
                        }

                        &:after{
                            height: 100%;
                            transition: all 50ms ease-in-out 125ms;
                        }
                    }
                }
            }

            &--actus{
                background-color: white;

                h2{
                    text-align: center;
                    margin-bottom: $gap*2;
                }

                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 70%; height: calc(100% - 144px);
                    opacity: 0.65;
                    right: 0; bottom: 0;
                    background: url('https://epure2a.dev.docker/themes/Epure/assets/img/fond_actu.jpeg') no-repeat 50% 50%;
                    background-size: cover;
                }

                .grille-annonce--l2{
                    margin: 0;
                    padding: $gap*2 0;

                    .ann{
                        margin-bottom: 0;
                    }
                }
            }

            &--newsletter{
                color: white;
                background-color: rgba(24, 24, 24, 0.43);
                padding-top: 15vh !important;
                padding-bottom: 15vh !important;

                @media screen and (max-width:1000px){
                    padding-top: $gap*3 !important;
                    padding-bottom: $gap*3 !important;
                }

                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    left:0; top: 0;
                    width: 100%; height: 100%;
                    background: url('https://epure2a.dev.docker/themes/Epure/assets/img/fond_newsletter.jpeg') no-repeat 50% 88%;
                    background-size: cover;
                    opacity: 0.5;
                }

                h2{
                    color: white;
                    text-align: left;
                }

                .txt{
                    color: white;
                    font-size: 20px;
                    font-weight: 400;
                    margin: 0;
                    text-align: left;
                }
            }
        }


        .flex{
            &--newsletter{
                z-index: 1;
                align-items: center;

                @media screen and (max-width:1000px){
                    flex-direction: column;
                }
                
                .flex-elem{
                    &:first-of-type{
                        flex-grow: 0.75;

                        @media screen and (max-width:1000px){
                            flex-grow: 1;
                        }
                    }

                    @media screen and (max-width:1000px){
                        margin: $gap 0;
                    }
                }

                form{
                    width: 100%;

                    &:after{
                        content:'';
                        display: block;
                        clear: both;
                        float: none;
                    }
                }

                input{
                    float: left;
                    text-transform: uppercase;
                    font-weight: 200;
                    padding: $gap*0.7 $gap*1.5;
                    width: calc(100% - 130px);
                    border: none;
                    box-shadow: none;
                    line-height: 21px;

                    &::placeholder{
                        text-transform: uppercase;
                        color: #00000029;
                    }
                
                    &[type=submit]{
                        float: right;
                        background-color: #9B9B9B;
                        color: white;
                        text-align: center;
                        width: 130px;
                        @include trans;

                        &:hover{
                            background-color: #808080;
                            @include trans;
                        }
                    }
                }
            }
        }


        //carroussel
        .owl-carousel {
            // display: none;
            // position: relative;
            // width: 100%;
            // -ms-touch-action: pan-y;
            // touch-action: pan-y;

            .owl-wrapper-outer {
                overflow: hidden;
                position: relative;
                width: 100%;
                
                &.autoHeight {
                    transition: height 500ms ease-in-out;
                }
            }

            .owl-wrapper {
                display: none;
                position: relative;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;

                &:after {
                    content: ".";
                    display: block;
                    clear: both;
                    visibility: hidden;
                    line-height: 0;
                    height: 0;
                }

                .owl-item {
                    float: left;
                    padding: 0;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;

                    .item {
                        position: relative;
                    }
                }
            }
            .owl-controls {
                user-select: none;
                -webkit-tap-highlight-color: transparent;

                .owl-page, .owl-buttons div {
                    cursor: pointer;
                }

                .owl-buttons{
                    > div{
                        background-color: transparent;
                        color: white;
                        box-shadow: none !important;

                        &.owl-prev{ left: 15px !important; }

                        &.owl-next{ right: 15px !important; }

                        &:hover{
                            color: $main;
                        }
                    }

                    .material-icons{ display: none; }
                }
            }

            .grabbing {
                cursor: url(../img/grabbing.png) 8 8, move;
            }
        }

        .poslistcategories{
            @media screen and (max-width:1000px){
                width: calc(100% - 36px);
                margin: 0 $gap $gap $gap;

                .row{
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }


    }
}