@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-primary;
        }
      }

      border: none;
      padding: 10px;
      min-width: 255px;
      background: $gray-light;
      color: $gray;

      &::placeholder{
        color: rgba($gray, 0.5);
        text-indent: 0.625rem;
      }
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.313rem;
      right: 0.125rem;
      color: $gray-dark;
      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 11px 0px rgba(0, 0, 0, 0.1);
}

/*
* HELPERS
*/



//display box par defaut
@mixin display($position:relative, $display:block, $z-index:false, $text-align:false, $overflow:false, $float:false){
	@if $position != false {position:$position;}
	@if $display != false {display:$display;}
	@if $z-index != false {z-index:$z-index;}
	@if $text-align != false {text-align:$text-align;}
	@if $overflow != false {overflow:$overflow;}
	@if $float != false {float:$float;}
}

// configurer pour l'absolute
@mixin absolute($display:block, $z-index:false, $text-align:false, $overflow:false, $width:100%, $height:100%, $top:0, $left:0, $bottom:false, $right:false){

	@include display($position:absolute, $display:$display, $z-index:$z-index, $text-align:$text-align);

	@if $width!=false{width:$width;}
	@if $height!=false{	height:$height;}
	@if $top!=false{top:$top;}
	@if $left!=false{left:$left;}
	@if $bottom!=false{bottom:$bottom;}
	@if $right!=false{right:$right;}
}

// raccourci pour le box-sizing
@mixin bxs($box-sizing:border-box){
	box-sizing:$box-sizing;
}

@mixin display-flex{
	display:block;
	display:flex;
}


/*
* TRANSITIONS ET ANIMATIONS
*/

// mixin pour transitions
@mixin trans($property:all, $duration:225ms, $delay:0ms, $easing:ease-in-out){
	transition: $property $duration $delay $easing;
}

/*
* RESPONSIVE
*/
$resp-large : 1480px;
$resp-normal : 1280px;
$resp-tablette: 1000px;
$resp-android: 480px;
$resp-iphone : 320px;

@mixin respond-to($taille, $taille-min:0px){
	@if $taille == large{@media only screen and (max-width:$resp-large) and (min-width:$taille-min){@content;}}
	@if $taille == ipad{@media only screen and (max-width:$resp-ipad) and (min-width:$taille-min){@content;}}
	@if $taille == normal{@media only screen and (max-width:$resp-normal) and (min-width:$taille-min){@content;}}
	@if $taille == tablette{@media only screen and (max-width:$resp-tablette) and (min-width:$taille-min){@content;}}
	@if $taille == android{@media only screen and (max-width:$resp-android) and (min-width:$taille-min){@content;}}
	@if $taille == iphone{@media only screen and (max-width:$resp-iphone) and (min-width:$taille-min){@content;}}

	@if ($taille != large ) and ($taille != ipad) and ($taille != normal) and ($taille != tablette) and ($taille != android) and ($taille != iphone){
		@media only screen and (max-width:$taille) and (min-width:$taille-min){@content;}
	}
}

@mixin respond-from($taille){
	@if $taille == large{@media only screen and (min-width:$resp-large){@content;}}
	@if $taille == ipad{@media only screen and (min-width:$rep-ipad){@content;}}
	@if $taille == normal{@media only screen and (min-width:$resp-normal){@content;}}
	@if $taille == tablette{@media only screen and (min-width:$resp-tablette){@content;}}
	@if $taille == android{@media only screen and (min-width:$resp-android){@content;}}
	@if $taille == iphone{@media only screen and (min-width:$resp-iphone){@content;}}

	@if ($taille != large ) and ($taille != ipad) and ($taille != normal) and ($taille != tablette) and ($taille != android) and ($taille != iphone){
		@media only screen and (min-width:$taille){@content;}
	}
}

// calcul de largeur
@mixin widther($width:auto, $g:$gap, $fallback:$gap-ie-fallback){
	@if $width != auto{
		width: $width - $fallback;
		width: calc(#{$width} - #{$g});
	}
}


/* SYSTEME DE GRILLE */
@mixin grid($flex-direction:row, $align-items:stretch, $flex-wrap:no-wrap){
	@include display-flex;
    @if $flex-direction != row{
      flex-direction: $flex-direction;
    }
    @if $flex-wrap != no-wrap{
	    flex-wrap: $flex-wrap;
    }
	align-items:$align-items;
	width:100%;
	@include bxs;
}

@mixin grid-elem($flex-grow:1, $flex-shrink:1, $flex-basis:0){
	@include display-flex;
    order:1;
	
	@if $flex-shrink != 1 and $flex_basis != 0{
    flex: $flex-grow $flex-shrink $flex-basis;
	}@else if $flex-shrink != 1{
		flex: $flex-grow $flex-shrink;
	}@else{
		flex: $flex-grow;
	}

	margin-right:$gap;

	&:last-of-type{
		margin-right:0;
	}

	&.floatright{
		@include respond-from(tablette){
            margin-left:$gap;
            order:2;
        }
	}
}

/* SYSTEME DE RATIOS POUR LA HAUTEUR */
/* systeme de ratio hauteur css */
@function getRatio($width, $height){
	@return math.div($height, $width) * 100%;
}

@mixin aspect-ratio($width, $height){
	position:relative;
	height:auto !important;
	
	&:before{
		@include display;
		content:'';
		padding-top: getRatio($width, $height);
	}
}

/* SYSTEME DE GRILLE */
@mixin grid($flex-direction:row, $align-items:stretch, $flex-wrap:no-wrap){
	@include display-flex;
    @if $flex-direction != row{
      flex-direction: $flex-direction;
    }
    @if $flex-wrap != no-wrap{
	    flex-wrap: $flex-wrap;
    }
	align-items:$align-items;
	width:100%;
	@include bxs;
}

@mixin grid-elem($flex-grow:1, $flex-shrink:1, $flex-basis:0){
	@include display-flex;
    order:1;
	
	@if $flex-shrink != 1 and $flex_basis != 0{
    flex: $flex-grow $flex-shrink $flex-basis;
	}@else if $flex-shrink != 1{
		flex: $flex-grow $flex-shrink;
	}@else{
		flex: $flex-grow;
	}

	margin-right:$gap;

	&:last-of-type{
		margin-right:0;
	}

	&.floatright{
		@include respond-from(tablette){
            margin-left:$gap;
            order:2;
        }
	}
}


//calcul de la largeur d'une annonce en fonction d'un margin et d'un nombre d'annonces par ligne
@mixin autoWidther($nombre_par_ligne,$gap:$gap){
	@include widther(percentage(calc(1 / $nombre-par-ligne)), ceil((($gap) - (ceil(calc($gap / $nombre-par-ligne))-1))));
}

// instructions de responsive pour les 6 annonces / ligne
@mixin annonce--l6($g:$gap){
	@include respond-to(android){
		width:100%;
		margin-right:0;
	}

	@include respond-to(640px, $resp-android+1){
		@include autoWidther(2, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g;}
		&:nth-child(2n+0){margin-right:0;}
	}

	@include respond-to($resp-normal, 640px+1){
		@include autoWidther(3, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g;}
		&:nth-child(3n+0){margin-right:0;}
	}

	@include respond-to($resp-large, $resp-normal+1){
		@include autoWidther(4, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g;}
		&:nth-child(4n+0){margin-right:0;}
	}

	@include respond-from($resp-large+1){
		@include autoWidther(6, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g;}
		&:nth-child(6n+0){margin-right:0;}
	}
}


@mixin annonce--l5($g:$gap){
	@include respond-to(740px){
		width:100%;
		margin-right:0;
	}

	@include respond-to($resp-tablette , 741px){
		@include autoWidther(3, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(3n+0){margin-right:0;}
	}

	@include respond-to($resp-large ,$resp-tablette+1){
		@include autoWidther(4, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(4n+0){margin-right:0;}
	}

	@include respond-from($resp-large+1){
		@include autoWidther(5, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(5n+0){margin-right:0;}
	}
}


@mixin annonce--l4($g:$gap){
	@include respond-to(740px){
		width:100%;
		margin-right:0;
	}

	@include respond-to($resp-tablette , 741px){
		@include autoWidther(2, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(2n+0){margin-right:0;}
	}

	@include respond-to($resp-normal ,$resp-tablette+1){
		@include autoWidther(3, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(3n+0){margin-right:0;}
	}

	@include respond-from($resp-normal+1){
		@include autoWidther(4, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(4n+0){margin-right:0;}
	}
}



@mixin annonce--l3($g:$gap){
	@include respond-to(640px){
		width:100%;
		margin-right:0;
	}

	@include respond-to($resp-tablette, 640px+1){
		@include autoWidther(2, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(2n+0){margin-right:0;}
	}

	@include respond-from($resp-tablette+1){
		@include autoWidther(3, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-child(3n+0){margin-right:0;}
	}
}



@mixin annonce--l2($g:$gap){
	@include respond-to(750px){
		width:100%;
		margin-right:0;
	}

	@include respond-from(751px){
		@include autoWidther(2, $g);
		margin-right:$g;
		//&:last-of-type{margin-bottom:$g/2;}
		&:nth-of-type(2n+0){margin-right:0;}
	}
}
