main .header-container {
	z-index: 15 !important;
	position:relative;
    background-color: transparent;
    background-image: url('https://epure2a.dev.docker/themes/Epure/assets/img/bg_header.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:before{
        content: '';
        display: block; position: absolute;
        left:0; bottom: 0;
        width: 100%; height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    }

    .name_category {
        margin-top: 40px;
        text-align:center;	
        margin-bottom:40px;
        display:inline-block;
        width:100%;

        h2 {
            display: inline-block;
            position: relative;
            font-size: 30px;
            font-weight: 700;
            color: white;
            margin:0;
            line-height:1;
            text-transform:uppercase;
        }
    }
}

#header{
    color: $gray;
    padding: 0;
    position: relative;
    text-align: center;
    background-color: transparent;

    .page-index &{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .header-top{
            @include respond-to(tablette){
                height: 100vh;

                .container{
                    height: 100vh;
                }
            }
        }
    }

    .header{
        // NAVIGATION LATERALE
        &-nav{
            line-height: 1;
            position: fixed;
            right: 0;
            top: 50%;
            border: 0;
            margin: 0;
            max-height: none;
            z-index: 102;
            transform: translateY(-50%);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
            transition: all 300ms ease-in;

            @media (max-width: 1600px) {
                top: 40%;
            }

            #menu-icon {
                vertical-align: middle;
                cursor: pointer;
                margin-left: 1px;

                .material-icons {
                    line-height: 50px;
                }
            }

            .right-nav {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-end;
            }

            .currency-selector {
                margin-top: 0.938rem;
                margin-left: 0.938rem;
                white-space: nowrap;
            }

            .user-info {
                margin-left: 2.5rem;
                margin-top: 0.938rem;
                text-align: right;
                white-space: nowrap;

                .account {
                    margin-left: $small-space;
                }
            }

            .language-selector {
                margin-top: 0.938rem;
                white-space: nowrap;
            }

            .cart-preview {

                &.active {
                    background: $brand-primary;

                    a { color: white; }
                    i { color: rgba(255, 255, 255, 0.6); }
                }

                .shopping-cart { vertical-align: middle; }
                .body { display: none; }
            }

            .blockcart {
                background: $gray-light;
                height: 3rem;
                padding: 0.75rem;
                margin-left: 0.938rem;
                text-align: center;
                white-space: nowrap;

                a { color: $gray;

                    &:hover { color: $brand-primary; }
                }

                &.active {
                    a:hover { color: white; }
                }

                .header { margin: 0; }
            }

            #contact-link { margin-top: 0.938rem; }

            .material-icons {
                line-height: inherit;
                
                &.expand-more { margin-left: -0.375rem; }
            }
        }

        // NAVIGATION TOP (cta fixes)
        &-top{
            position: relative;
            padding: 0;
            background: transparent;
            z-index: 100;

            > .container {
                position: relative;
            }

            .header_logo, .display_nav{
                padding-top: 20px;
                clear: both;
        
                @media (max-width: 991px) {
                    padding-top: 20px;
                }
            }

            .display_nav{
                @media screen and (max-width:999px){
                    display: none;
                }
            }

            .header_logo{
                padding-bottom: 20px;

                @include respond-to(tablette){
                    position: absolute;
                    left: 50%; top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    max-width: 100%;
                    text-align: center;

                    a{
                        display: block;
                        text-align: center;

                        .logo{
                            margin: 0 auto;
                        }
                    }
                }
            }

            #_desktop_top_menu{
                @include respond-to(tablette){
                    display: none;
                }
            }

            .display_top {
                position: relative;
                justify-content: flex-end;
                display: flex;
                align-items: center;
                margin-top: 20px;

                #_desktop_top_menu{
                    position: relative;
                    max-width: 81%;
                    margin: 0; padding: 0;

                    ul.top-menu{
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                    
                        li.link, .cms-page{
                            position: relative;
                            display: flex;
                            float: none; clear: both;
                            margin: 0 $gap*0.5;

                            a, .dropdown-item{
                                display: block;
                                color: white;
                                font-family: $typo-title;
                                text-transform: none;
                                font-weight: 400;
                                font-size: 20px;
                                padding: 0 0 $gap*0.5 0;
                            }

                            &:before{
                                content: '';
                                position: absolute;
                                display: block;
                                left: 50%; transform: translateX(-50%);
                                bottom: 0;
                                width: 0; height: 2px;
                                background-color: white;
                                transition: all 75ms ease-in-out;
                            }

                            &:hover{
                                &:before{
                                    width: 100%;
                                    transition: all 150ms ease-in-out;
                                }
                            }
                        }
                    }
                }
            }

            .menu {
                padding-left: 15px;
                margin-bottom: 0.375rem;

                > ul {
                    > li { float: left; }
                }
            }

            .position-static {
                position: static;
            }

            a[data-depth="0"] {
                color: $gray;
                text-transform: uppercase;
            }

            .page_sidebar &{
                @media (min-width: 992px) {
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(0);
                    transition: transform 0.8s ease, opacity 0.5s ease, visibility 0.5s ease;

                    .close-sidebar {
                        opacity: 1;
                    }
                }
            }

            .left_column_fix {
                @media (min-width: 992px) {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(22, 22, 27, 0.97);
                    opacity: 0;
                    z-index: 10;
                    padding: 45px 45px 0 45px;
                    visibility: hidden;
                    transform: scale(0.7);
                    transition: transform 0.8s ease, opacity 0.5s ease, visibility 0.5s ease;

                    &.active{
                        opacity: 1;
                        visibility: visible;
                        transform: scale(1);
                    }

                    .logo_left_img{
                        margin: 0;
                    }
                }
            }

            .sidebar_left{
                .open-sidebar{
                    @media (min-width: 992px) {
                        position: absolute;
                        top: 45px;
                        left: 15px;
                        color: #343940;
                        font-weight: 700;
                        font-size: 12px;
                        text-transform: uppercase;
                        cursor: pointer;

                        &:hover {
                            color: #ffa800;
                            transition: all 300ms ease-in;
                        }

                        &:after{
                            font-size: 18px;
                            display: inline-block;
                            font-family: "FontAwesome";
                            content: "\f0c9";
                            font-weight: normal;
                            margin: 0 10px;
                            vertical-align: -1px;
                        }
                    }
                }

                .close-sidebar{
                    @media (min-width: 992px) {
                        position: fixed;
                        right: 45px;
                        top: 47px;
                        z-index: 11;
                        color: #343940;
                        font-weight: 700;
                        font-size: 12px;
                        opacity: 0;
                        text-transform: uppercase;
                        color: white;
                        cursor: pointer;

                        &:hover{
                            color: #ffa800;
                            transition: all 300ms ease-in;
                        }

                        &:after{
                            content: "\f00d";
                            font-size: 18px;
                            display: inline-block;
                            font-family: "FontAwesome";
                            font-weight: normal;
                            margin: 0 10px;
                            vertical-align: -1px;
                        }
                    }
                }
            }
        }

        
    }

    // LOGO
    .logo {
        max-width: 100%;
        margin: 0;
        filter: invert(100%);

        @media (max-width: 991px) {
            margin: auto;
        }

        a {
            display:block;
            color: $gray-darker;

            &:hover {
                text-decoration: none;
                color: $brand-primary;
            }
        }            
    }


    .top-menu-link {
        margin-left: $medium-space;
    }

    .header-content{
        @include respond-to(tablette){
            padding-top: 30px;
        }

        body.page-index &{
            height: 100vh;
            
            .scroll-menu &{
                height: auto;
            }

            @include respond-to(tablette){
                height: 100vh;
                padding-top: 0;
                
                > .container{
                    height: 100vh;
                }
            }
        }

        .header-middle{
            z-index: 102;

            @include respond-to(tablette){
                position: absolute;
                top: 30px;
                width:100%;
            }

            body.page-index &{
                position: absolute;
                bottom: $gap*2; left: 0;
                width: 100%;

                @include respond-to(tablette){
                    //top: -21px;
                    bottom: auto;
                }
            }

            .ma-nav-mobile-container{
                display: none;
        
                @media(max-width: 991px) {
                    display: block;
                }
            }
        }


    }

    &.scroll-menu{
        @media (min-width: 992px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1001;
            background: rgba(0, 0, 0, 0.85);
            box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
            transition: all 300ms ease-in;

            &:hover{
                background: black;
                transition: all 300ms ease-in;
            }

            .sub-page &{
                background-color: rgba(0, 0, 0, 0.85);
                position: fixed;
    
                &:hover{
                    background-color: black;
                    transition: all 300ms ease-in;
                }
            }
        }

        .header-content{
            height: auto;

            .header-top{
                @media (min-width:992px){
                    display: none;
                }
            }
        }

        .header-logo{
            @media (min-width: 992px) {
                padding-top: 25px;
            }
        }

        .logo{
            @media (min-width: 992px) {
                max-width: 125px;
            }
        }

        .header-content{
            @media (min-width:992px){
                position: relative;
                z-index: 2;
            }
        }

        .blockcart{
            .header{
                a{
                    @media (min-width:992px){
                        height: 70px;
                    }
                }
            }

            .block-search{
                .search-icon{
                    @media (min-width:992px){
                        height: 70px;
                    }
                }
            }
        }
    
        

        .sidebar_left{
            .open-sidebar{
                @media (min-width:992px){
                    top: 25px;
                }
            }
        }
    }
}

.slideshow_container{
    &:before{
        content:'';
        display: block; z-index: 8;
        position: absolute;
        left: 0; right: 0; bottom: 0;
        width: 100%; height: 33%;
        background: linear-gradient(to top, rgba(black, 1) 10%, rgba(black,0));
    }

    &:after{
        content:'';
        display: block; z-index: 8;
        position: absolute;
        left: 0; right: 0; top: 0;
        width: 100%; height: 100%;
        background-color: black;
        opacity: 0.12;
    }

    .nivo-controlNav{
        display: none;
    }

    .nivoSlider{
        height: 100vh;
    }

    .nivo-main-image{
        @include respond-to(1350px){
            width: auto !important;
            height: 100% !important;
            left: 50%; transform: translateX(-50%);
        }
    }
}


.sub-menu{
    .scroll-menu{
        @media (min-width: 992px) {
            margin-bottom: 0;
        }
    }

    .header-container {
        //background: url(../img/bg_header.jpg) no-repeat center;
        background-size: cover;
    }
}

@media (min-width: 1200px) {
    .sibar_left .open-sidebar {
        left: 80px;
    }
}

.sub-page{
    .sibar_left{
        .open-sidebar{
            color: white;

            &:hover {
                color: #ffa800;
            }
        }
    }

    .pt_custommenu{
        .pt_menu{
            .parentMenu{
                a { color: white; }
            }
        }
    }

    .pos_logo{
        margin: 0;

        .container{
            border: 0;
        }
    }
}

.sale_off_block {
    overflow: hidden;
    margin-top: 125px;
}
