.contact-rich {
  color: $gray-dark;
  margin-bottom: 2rem;
  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: 2rem;
  }
  .block {
    height: auto;
    overflow: hidden;
    .icon {
      float: left;
      width: 3.5rem;
      i {
        font-size: 2rem;
      }
    }
    .data {
      color: $gray-darker;
      font-size: $font-size-sm;
      width: auto;
      overflow: hidden;
      &.email{
        padding-top: 0.375rem;
      }
    }
  }
}
.contact-form {
  @include box-shadow;
  background: white;
  padding: 1rem;
  color: $gray-dark;
  width: 100%;
  h3 {
    text-transform: uppercase;
    color: $gray-darker;
  }
}
